import React, { useState, useEffect, useContext, useRef } from 'react'
import { Link } from 'react-router-dom';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { OverlayTrigger, Tooltip, Form } from "react-bootstrap";
import { Popover } from "react-bootstrap"
import FormHelperText from '@mui/material/FormHelperText';
import { Divider, Typography, Skeleton, Stack, Grid, InputLabel, Box, IconButton } from '@mui/material';
import Check from '@mui/icons-material/Check';
import { List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { DEFAULT_ADJUSTMENT_VALUE, LOGIN_ROUTE, ROUTE_VIEW_PAYMENTS } from '../../utilities/staticConfigs';
import { format } from "date-fns";
import "./style.css";
import service from "./service";
import { useHistory } from "react-router-dom";
import Decimal from 'decimal.js';
import UndoIcon from '@mui/icons-material/Undo';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import LoadingContext from '../../container/loadingContext';


import i18n from "../../utilities/i18n";
import { getStorage } from "../../utilities/browserStorage";
import { CommonDateFormat, checkPermission, currencyFormat, formatDateUS } from "../../utilities/commonUtilities";
import {
  CREDIT_CARD_TYPES,
  INSURANCEPAYMENTMODES,
  CLAIM_TYPES,
  RESPONSIBILITY_TYPES,
} from "../../utilities/dictionaryConstants";
import {
  CLAIMDATEOFSERVICERANGE,
  DEFAULT_PAGING_SIZE,
  PAYEMNT_MODES_CREDITCARD_ID,
  ROUTE_SEARCH_CLAIMS,
  MAX_OPEN_TABS_CLAIMS,
} from "../../utilities/staticConfigs";

import { filterDateValues } from "../../utilities/dateFilterCalculation";

import CalendarInput from "../commons/input/CalendarInput";
import TextInput from "../commons/input/input";
import SelectInput from "../commons/input/SelectInput";
import TextAreaInput from "../commons/textarea/textarea";
import Notify from "../commons/notify";

import CustomizedDialogs from "../modalWindowComponent/CustomizedDialogs";

import AsyncTypeInput from "../commons/input/AsyncTypeHead/AsyncTypeInput";
import service1 from "../ClaimsModule/SearchClaims/service";
import RemitanceCodeMultiSelect from "./RemitanceCodeMultiSelect";
import CustomizedSmallDialogs from "../modalWindowComponent/CustomisedSmallDialog";
import CurrencyInputs from '../commons/input/CurrencyInput';
import { MaterialMultiselect } from '../../MaterialMultiselect/MaterialMultiselect';
import { claimListHeadings } from './InsuranceTableHeading';
import { permission_key_values_payments } from '../../utilities/permissions';
import CommonButton from '../commons/Buttons';
import { Tooltip as MUITooltip } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/Input';
import ReportRoundedIcon from '@mui/icons-material/ReportRounded';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const labelStyle = {
  color: "var(--Grey-G600, #757575)",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Lato",
  fontSize: "13px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  marginBottom: '5px'
};

const typographyStyle = {
  overflow: "hidden",
  color: "var(--Grey-G900, #212121)",
  fontFeatureSettings: "'clig' off, 'liga' off",
  textOverflow: "ellipsis",
  fontFamily: "Lato",
  fontSize: "13px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal"
};

const InsurancePayments = (props) => {
  const history = useHistory();
  const setShowLoadingBar = useContext(LoadingContext);
  // const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const DROP_CHARGES_DATA = [
    { 'id': 'charges', 'name': i18n.t("payments.post_payments.chargesWithBalance") },
    { 'id': 'all', 'name': i18n.t("payments.post_payments.allCharges") }
  ]
  const practicePK = getStorage('practice');
  const activePage = 0;
  const [insurancePaymentData, setInsurancePaymentData] = useState('');
  const [appliedAmount, setAppliedAmount] = useState('');
  const [unAppliedAmount, setUnAppliedAmount] = useState('');
  // Variable used to store data against an index / procedure in procedure payment grid as object of object
  const [procedureData, setProcedureData] = useState([]);
  const [procedureRowErrors, setProcedureRowErrors] = useState([]);
  const [flag, setFlag] = useState(false);
  const [responsibilityData, setResponsibilityData] = useState([]);
  const [responsibilityDataList, setResponsibilityDataList] = useState([]);
  const [isEditButtondisable, setIsEditButtondisable] = useState(true);
  const CLAIM_SEARCH_OPTIONS = [
    { id: "claim_pk", name: "Claim" },
    { id: "patient_pk", name: "Patient" },
    { id: "provider_pk", name: "Provider" },
    { id: "payer_pk", name: "Payer" },
  ];
  const [searchOption, setSearchOption] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [error, setError] = useState({});
  const defaultSearchOption = "claim_pk";
  // let adjust = [{"id": 6469, "name": "DRG weight. (Handled in CLP12)"}];
  // post as credit modal window
  const [showPostAsCreditModal, setShowPostAsCreditModal] = useState(false);
  const [header, setHeader] = useState("");
  // search result modal window
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const [showAlertModalWindow, setShowAlertModalWindow] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const[showResetButton,setShowResetButton]= useState(false);
  const [prTotal, setPrTotal] = useState();
  const [selectedPayer, setSelectedPayer] = useState([]);
  // Insurance payment variable Used to save Payment details
  const [insurancePaymentVariables, setInsurancePaymentVariables] = useState({
    payer: "",
    payment_date: "",
    paymentDate: "",
    reference_check_number: "",
    amount: "",
    payment_mode: "",
    card_type: null,
    level_adjustments: 0,
    adjustment_type: [],
    note: "",
  });
  // edit modal window
  const [showEditPaymentModal, setShowEditPaymentModal] = useState(false);
  // Variable used for editing Basic details of payment
  const [editPaymentVariables, setEditPaymentVariables] = useState({
    payer: "",
    payment_date: "",
    paymentDate: "",
    reference_check_number: "",
    amount: "",
    payment_mode: "",
    card_type: null,
    level_adjustments: "",
    adjustment_type: [],
    note: "",
    custom_payment_id: "",
  });
  // search variables advanced
  const [claimSearchVariables, setClaimSearchVariables] = useState({
    claim_id: "",
    patient_id: "",
    claim_status: "",
    responsibility_type: "",
    claim_type: "",
    claim_date_of_service_from: "",
    claim_date_of_service_to: "",
    claim_date_type: 1,
    claim_submitted_date_from: "",
    claim_submitted_date_to: "",
    claim_submitted_date_type: 1,
    reversal_payent: false,
  });

  //old responsibility storing
  const [oldResponsibility, setOldResponsibility] = useState([]);
  // patient search
  const [patientDropdown, setPatientDropdown] = useState([]);
  // Variable for master claims details in search patients
  const [masterClaims, setMasterClaims] = useState([]);
  // search variables advanced api arrays
  const [claimStatusList, setClaimStatusList] = useState([]);
  // payer list
  const [payersList, setPayersList] = useState([]);
  // adjustment reasoncode
  const [adjustmentTypes, setAdjustmentTypes] = useState([]);
  // adjustment codes query list
  const [adjustment_1_codes_list, setAdjustment_1_codes_list] = useState([]);
  const [adjustment_2_codes_list, setAdjustment_2_codes_list] = useState([]);

  const [selectCharge, setSelectCharge] = useState("charges");
  const [selectedClaimVals, setSelectedClaimVals] = useState([]);
  const [claimSubStatusList, setClaimSubStatusList] = useState([]);
  const [isFetchingResponsibilityData, setIsFetchingResponsibilityData] = useState(false);

  // notify
  const [showNotify, setShowNotify] = useState("hide");
  const [notifyDescription, setNotifyDescription] = useState("");
  const [notifyType, setNotifyType] = useState("success");
  const [responsible_type, setResponsible_type] = useState([])

  // async-drop-down-list results
  const [maxResults, setMaxResults] = useState(5);

  // useEffect(() => {
  //   let newWidth = "";
  //   newWidth = window.innerWidth;
  //   let menuWidth = document
  //     .getElementById("push-bar")
  //     .classList.contains("open");
  //   if (!menuWidth) {
  //     newWidth = newWidth + 250;
  //   } else {
  //     newWidth = window.innerWidth;
  //   }
  //   setInnerWidth(newWidth);
  // }, []);


  const typeHeadAdjustment1Ref = useRef([]);
  const typeHeadAdjustment2Ref = useRef([]);

  useEffect(() => {
    if (props.claimID) {
      setSelectedClaimVals([props.selectedClaim]);
    }
  }, [props.selectedClaim]);

  function showNotifyWindow(action, type, desc, age = 3000) {
    if (action == "show") {
      setTimeout(() => {
        setShowNotify("hide");
      }, age);
    }
    setShowNotify(action);
    setNotifyType(type);
    setNotifyDescription(desc);
  }

  const getClaimStatusData = (pageSize) => {
    setShowLoadingBar(true);
    let pageNumber = 0;
    const result = service.ListClaimStatus(pageSize, pageNumber, practicePK, null);
    result.then((response) => {
      setShowLoadingBar(false);
      setClaimStatusList(response.data);
    });
  };
  
  const getClaimStatusSubStatusData = async (pageSize, page, practicePK) => {
    try {
      const response = await service.ListClaimStatusSubStatus(pageSize, page,practicePK);
      setClaimSubStatusList(response.data);
    }
    catch (error) {
      console.error("Error while fetching claim sub-Status list:", error);    
    }
}

useEffect(()=> {
  if (!claimSubStatusList.length) getClaimStatusSubStatusData(0, 0,practicePK)
},[])

  function getPayerData(pageSize,activePage,searchValue) {
    setShowLoadingBar(true);
    const result = service.ListInsuranceNames(pageSize, activePage, practicePK, searchValue); // PASS ZERO FOR NO PAGINATION
    result.then((response) => {
      setShowLoadingBar(false);
      setPayersList(response?.data || []);
    }).catch(()=>{
      setPayersList([]);
    })
   
  }

  const getAdjustmentTypes = async (pageSize, page) => {
    try {
      setShowLoadingBar(true);
      const response = await service.GetListAdjustmentTypes(DEFAULT_PAGING_SIZE, page, "dropdown");

      if (response?.data) {
        const newArray = response.data?.map(obj => {
          return {
            id: obj.id,
            name: obj.name,
            label: obj.name
          }
        });
        setAdjustmentTypes(newArray || []);
      } else {
        setAdjustmentTypes([]);
      }
    } catch (error) {
      console.error("An error occurred while fetching adjustment types:", error);
    } finally {
      setShowLoadingBar(false);
    }
  }


  const debounce = (func, delay) => {
    let timeoutId;
    return function (input) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(input), delay);
    };
  };

  const onSearchAdjustmentReason_1_code = debounce(query => {
    if(query){
      query += "&practice_pk=" + practicePK;
      const result = service.GetListAdjustmentReasonCode(
        DEFAULT_PAGING_SIZE,
        activePage,
        query,
        "auto"
      );
      result.then((response) => {
        // filter the result to remove any item which has just a number value -- should return items that starts with PR,CO, PI & OA
        setAdjustment_1_codes_list(
          response.data.filter(
            (item) =>
              !(Object.prototype.hasOwnProperty.call(item, "label") && /^\d+$/.test(item.label))
          )
        );
      });
    } else {
  setAdjustment_1_codes_list([]);
    }
  }, 500);


  function handleSearchAdjustmentReason_1_code(query) {
    if (query) {
      onSearchAdjustmentReason_1_code(query);
    }
  }

  function handleSearchAdjustmentReason_2_code(query) {
    if (query) {
      onSearchAdjustmentReason_2_code(query);
    }
  }

  const onSearchAdjustmentReason_2_code = debounce(query => {
    if (query) {
      query += "&practice_pk=" + practicePK;
      const result = service.GetListAdjustmentReasonCode(
        DEFAULT_PAGING_SIZE,
        activePage,
        query,
        "auto"
      );
      result.then((response) => {
        // filter the result to remove any item which has just a number value -- should return items that starts with PR,CO, PI & OA as adjustment codes
        setAdjustment_2_codes_list(
          response.data.filter(
            (item) =>
              !(Object.prototype.hasOwnProperty.call(item, "label") && /^\d+$/.test(item.label))
          )
        );
      });
    } else {
      setAdjustment_2_codes_list([]);
    }
  }, 500);

  function getRemittanceRemarkCodeData(pageSize, page) {
    const result = service.GetListRemittanceRemarkCode(
      pageSize,
      page,
      practicePK,
      null,
      null
    );
    result.then(() => {
    });
  }


  function onHandleAdjustment_1_code(e, index) {
    if (e.length > 0) {
      let procedureCodes = [...procedureData[index]["adjustment_code1_data"], ...procedureData[index]['adjustment_code2_data']];
      if (procedureCodes.some((item) => item.id === e[0].id)) {
        showNotifyWindow("show", "error", "Duplicate entry.");
        clearTypeHeadSearchValue("adjustment_code1_data", index)
        return;
      }
      if (
        (e[0].label === 'PR-1' || e[0].label === 'PR-2' || e[0].label === 'PR-3')
      ) {
        showNotifyWindow("show", "error", "PR-1 PR-2 & PR-3 Codes should be added to Adjustment 2 Code set.");
        clearTypeHeadSearchValue("adjustment_code1_data", index)
        return;
      }
      setProcedureData((prevState) => {
        const newProcedureData = [...prevState];
        newProcedureData[index]["adjustment_code1_data"] = [...newProcedureData[index]["adjustment_code1_data"], { ...e[0], amount: 0 }];
        return newProcedureData
      })
      clearTypeHeadSearchValue("adjustment_code1_data", index)
    }
  }


  function onHandleAdjustment_2_code(e, index) {
    if (e.length > 0) {
      let procedureCodes = [...procedureData[index]["adjustment_code2_data"], ...procedureData[index]['adjustment_code1_data']];
      if (procedureCodes.some((item) => item.id === e[0].id)) {
        showNotifyWindow("show", "error", "Duplicate entry.");
        clearTypeHeadSearchValue("adjustment_code2_data", index)
        return;
      }
      if (
        (e[0].label === 'CO-253')
      ) {
        showNotifyWindow("show", "error", "CO-253 should be added to Adjustment 1 Code set.");
        clearTypeHeadSearchValue("adjustment_code2_data", index)
        return;
      }
      setProcedureData((prevState) => {
        const newProcedureData = [...prevState];
        newProcedureData[index]["adjustment_code2_data"] = [...newProcedureData[index]["adjustment_code2_data"], { ...e[0], amount: 0 }];
        return newProcedureData
      })
      clearTypeHeadSearchValue("adjustment_code2_data", index)
    }
  }

  /**
   * @param {*} adjustment1or2 whether the typehead is from adjustment or 2 
   * @param {*} index the index from procedureRow
   */
  function clearTypeHeadSearchValue(adjustment1or2, index) {
    // Clear the input value from async type head when an item is selected by the user
    if (adjustment1or2 === 'adjustment_code1_data') {
      if (adjustment1or2 === 'adjustment_code1_data') {
        if (typeHeadAdjustment1Ref.current[index] && typeof typeHeadAdjustment1Ref.current[index].clear === 'function') {
          typeHeadAdjustment1Ref.current[index].clear();
        }
      }
    } else if (adjustment1or2 === 'adjustment_code2_data') {
      if (adjustment1or2 === 'adjustment_code2_data') {
        if (typeHeadAdjustment2Ref.current[index] && typeof typeHeadAdjustment2Ref.current[index].clear === 'function') {
          typeHeadAdjustment2Ref.current[index].clear();
        }
      }
    }
  }

  const handleDeleteAdjustment = (e, removeIndex, index, adjustment) => {
    setProcedureData((prevState) => {
      const newProcedureData = [...prevState];
      const newEraAdjustments1 = [...newProcedureData[index][adjustment]];
      newEraAdjustments1.splice(removeIndex, 1);
      newProcedureData[index][adjustment] = newEraAdjustments1;
      let totalAdjustment2Amount = newProcedureData[index].adjustment_code2_data?.reduce((totalAdjustmentAmnt, adjustmentItem) => {
        return totalAdjustmentAmnt + (Number(adjustmentItem?.amount) || 0);
      }, 0);
      newProcedureData[index]['closing_balance'] = totalAdjustment2Amount;
      if (parseFloat(newProcedureData[index]['closing_balance']) <= 0) {
        newProcedureData[index]["responsibility_type"] = oldResponsibility
      }
      
    // Handle the PR-x cases
    const resetFields = {
      'PR-1': 'deductibles',
      'PR-2': 'co_insurance',
      'PR-3': 'copay',
    };

    if (resetFields[e.label]) {
      newProcedureData[index][resetFields[e.label]] = '';
      newProcedureData[index].claim_status_id = '';
      newProcedureData[index].claim_sub_status_id = '';
    }
      return newProcedureData
    })
    onBlurApply("", index)
  }

  useEffect(() => {
    getClaimStatusData(DEFAULT_PAGING_SIZE, activePage);
    getPayerData(DEFAULT_PAGING_SIZE, activePage);
    getRemittanceRemarkCodeData(DEFAULT_PAGING_SIZE, activePage);
    getAdjustmentTypes(DEFAULT_PAGING_SIZE, 0);
  }, []);

  function onHandleMultiSelectSearchForRemarkCodes(value, index) {
    let arrayId = [];
    let arrayCodes = [];
    value.map((item) => {
      arrayId.push(item.id);
      arrayCodes.push(item);
    });
    let temp = JSON.parse(JSON.stringify(procedureData));
    if (value) temp[index]["remark_code"] = arrayId;
    else temp[index]["remark_code"] = [];
    temp[index]["selectedRemarkCodes"] = arrayCodes;
    setProcedureData(temp);
  }

  function onHandleChange(e) {
    if (e.target) {
      let name = e.target.name;
      let value =
        e.target.type === "checkbox" ? e.target.checked : e.target.value;
      if ( name === "selectCharge") {
        setSelectCharge(value);
      } else if (name === "amount" || name === "level_adjustments") {
        setInsurancePaymentVariables((prevValue) => {
          let newValue = value.trim().replace(/[^0-9.]/g, '');
          newValue = newValue.replace(/^(\d*\.\d{0,2})\d*$/, '$1');
          return {...prevValue, [name] : newValue}
        });
      }
      else {
        setInsurancePaymentVariables({
          ...insurancePaymentVariables, [name]: value
        });
      }
    }
  }
  function onHandleDate(value, name) {
    if (name === "paymentDate") {
      setInsurancePaymentVariables({
        ...insurancePaymentVariables,
        [name]: value,
      });
    }
  }

  useEffect(() => {
    if (
      insurancePaymentVariables.paymentDate &&
      new Date(insurancePaymentVariables.paymentDate).getTime() > 0
    )
      setInsurancePaymentVariables({
        ...insurancePaymentVariables,
        ["payment_date"]: format(
          insurancePaymentVariables.paymentDate,
          "yyyy-MM-dd"
        ),
      });
  }, [insurancePaymentVariables.paymentDate]);

  function onHandleSearchClaim(e) {
    if (e.target) {
      if (e.target.name !== "claim_date_type" || e.target.name !== "claim_submitted_date_type") {
        let name = e.target.name;
        let value =
          e.target.type === "checkbox" ? e.target.checked : e.target.value;
        setClaimSearchVariables({
          ...claimSearchVariables,
          [name]: value,
        });
      }

      if (e.target.name === "claim_date_type") {
        let dateRanges = filterDateValues(e.target.value);
        if (dateRanges) {
          setTimeout(() => {
            setClaimSearchVariables({
              ...claimSearchVariables,
              claim_date_type: e.target.value,
              claim_date_of_service_from: dateRanges.fromDate,
              claim_date_of_service_to: dateRanges.toDate
            });
          }, 50);
        }
      }
      if (e.target.name === "claim_submitted_date_type") {
        let dateRanges = filterDateValues(e.target.value);
        if (dateRanges) {
          setTimeout(() => {
            setClaimSearchVariables({
              ...claimSearchVariables,
              claim_submitted_date_type: e.target.value,
              claim_submitted_date_from: dateRanges.fromDate,
              claim_submitted_date_to: dateRanges.toDate
            });
          }, 50);
        }
      }

    } else {
      if (e[0]) {
        setClaimSearchVariables({
          ...claimSearchVariables,
          patient_id: e[0].id,
          patient_name: e[0].name,
        });
        listCliamsofPatient("patient", e[0].id);
      } else {
        setClaimSearchVariables({
          ...claimSearchVariables,
          patient_id: "",
          patient_name: "",
        });
      }
    }
  }

 
  function onHandleSearchClaimDate(value, name) {
    setClaimSearchVariables({
      ...claimSearchVariables,
      [name]: value,
    });
  }


  const [claimList, setClaimList] = useState([]);
  const [procedureList, setProcedureList] = useState([]);


  // Save Payment basic data
  function onSavePayment() {
    let data = insurancePaymentVariables;
    if (!data["level_adjustments"]) data["level_adjustments"] = 0;
    const result = service.SaveInsurancePayment(data);
    result.then((response) => {
      if (response.status === 201) {
        props.showNotifyWindow("show", "success", i18n.t("successMessages.payment_posted"));
        setTimeout(() => {
          props.setPaymentPK(response.data.id);
        }, 500);
      } else if (response.status === 500 || response.status === 400) {
        props.showNotifyWindow("show", "error", i18n.t("errorMessages.payment_error"));
      }
    });
  }

  // Populate procedure payment details which already saved against a Payment
    function SavedInsurancePaymentProcudures() {
    let responsibility_data = [];
    const patientsResponsibilityTypesList = [...responsibilityDataList];
    setShowLoadingBar(true);
    const result = service.SavedInsurancePaymentProcudures(props.paymentPK);
    result.then((response) => {
      let temp = JSON.parse(JSON.stringify(procedureData));
      response.data?.map((item, index) => {
        let adjustment_code1_data = [];
        let adjustment_code2_data = [];
        if (item.adjustment_1.length > 0) {
          for (var code1 in item.adjustment_1) {
            const code1Data = {
              ...item.adjustment_1[code1],
              dropdown_name: item.adjustment_1[code1].label
            }
            adjustment_code1_data.push(code1Data)
          }
        }

        if (item.adjustment_2.length > 0) {
          for (var code2 in item.adjustment_2) {
            const code2Data = {
              ...item.adjustment_2[code2],
              dropdown_name: item.adjustment_2[code2].label
            }
            adjustment_code2_data.push(code2Data)
          }
        }
        let remark_code = [];
        if (item.remark_code) {
          remark_code = item.remark_code;
        }
        temp[index] = {
          payment: props.paymentPK,
          claim: item.claim_id,
          procedure: item.procedure,
          dos_from: item.dos_from,
          custom_claim_id: item.custom_claim_id,
          allowed_amount: item.allowed_amount,
          paid_amount: item.paid_amount,
          remark_code: remark_code,
          opening_balance: item.balance,
          closing_balance: item.closing_balance ? item.closing_balance : getClosingBalance(item),
          charges: item.charges,
          adjustment_code1_data: adjustment_code1_data,
          adjustment_code2_data: adjustment_code2_data,
          responsibility_type: item.responsibility_type,
          copay: item.copay ? item.copay : 0,
          co_insurance: item.co_insurance ? item.co_insurance : 0,
          deductibles: item.deductibles ? item.deductibles : 0,
          pay_procedure_status: item.pay_procedure_status,
          save_procedure_id: item.save_procedure_id ? item.save_procedure_id : "",
          selectedRemarkCodes: item.selected_remark_codes,
          entry_reversed: item.entry_reversed,
          receipts: item.receipts,
          id: item.id,
          patient_id: item.patient_id ? item.patient_id : "",
          previous_responsibility_type: item.previous_responsibility_type,
          claim_status_id: item.claim_status_id,
          claim_sub_status_id: item.claim_sub_status_id
        };
        responsibility_data.push(item.responsibility_type);

        const responsibility = patientsResponsibilityTypesList.find(
          (item1) => item1.patientPk === item.patient_id
        );
        if (!responsibility) {
            patientsResponsibilityTypesList.push({
              patientPk: item.patient_id,
              responsibilityList: item.responsibility_list,
              flag: true
            })
        }
      })
      setResponsibilityDataList(patientsResponsibilityTypesList);

      setProcedureData(temp);

      setProcedureList(response.data);
      setOldResponsibility(responsibility_data)
      setResponsible_type(responsibility_data)
      localStorage.setItem('initialPaymentEntryByProcedure', JSON.stringify(response.data));
      setShowLoadingBar(false);

    }).catch(()=> {
      setShowLoadingBar(false);
      showNotifyWindow("show", "error",i18n.t('errorMessages.commonError'));
    })
  }

  function getClosingBalance(item) {
    let closing_balance = parseFloat(item.co_insurance) + parseFloat(item.copay)
      + parseFloat(item.deductibles);
    return closing_balance;
  }

  // Get Payment data
  function getPaymentData() {
    setShowLoadingBar(true);
    const result = service.GetInsurancePayment(props.paymentPK);
    result.then((response) => {
      setShowLoadingBar(false);
      setInsurancePaymentData(response.data);
      setUnAppliedAmount(response.data["total_amount"]);
      SetPaymentVariablesData(response.data);
      setIsEditButtondisable(false)
      // Load saved procedure payment data if it has already saved data
      if (response.data["has_procedurs"]) SavedInsurancePaymentProcudures();

    });
  }

  useEffect(() => {
    if (props.paymentPK) getPaymentData();
  }, [props.paymentPK]);

  // List procedures of a Claim
  async function listProceduresOfClaim(claimPK) {
    const paymentDos = editPaymentVariables.payment_date;
    let page = 0;
    const response = await service.ListInsurancePaymentProcedures(
      claimPK,
      DEFAULT_PAGING_SIZE,
      page,
      paymentDos
    );
    if (response?.data) {
      let patientsResponsibilityTypesList = [...responsibilityDataList];
      let proc_temp = procedureList;
      let new_procedures = [];
      let responsibility_data = [];
      let tempProcedureDatanow = procedureData;
      let tempProcedureData = []; // new procedure data obj
      let tempIndex = 0;
      response.data?.map((item) => {
        // checks for the procedure already listed
        if (!proc_temp.find((i) => i.id === item.id)) {
          let indexData = {
            payment: props.paymentPK,
            claim: item.claim_id,
            procedure: item.procedure,
            allowed_amount: 0,
            paid_amount: 0,
            receipts: item.receipts ? item.receipts : 0,
            custom_claim_id: item.custom_claim_id,
            dos_from: item.dos_from ? item.dos_from : "",
            remark_code: [],
            opening_balance: item.balance,
            closing_balance: 0,
            patient_id: item.patient_id ? item.patient_id : "",
            charges: item.charges,
            adjustment_code1_data: [],
            adjustment_code2_data: [],
            responsibility_type: item.responsibility_type,
            pay_procedure_status: 0,
            entry_reversed: false,
            id: item.id,
            claim_status_id: item.claim_status_id,
            claim_sub_status_id: item.claim_sub_status_id
          };
          tempProcedureData[tempIndex] = indexData
          tempIndex += 1;
          new_procedures.push(indexData);
          responsibility_data.push(item.responsibility_type)
          const responsibility = patientsResponsibilityTypesList.find(
            (item1) => item1.patientPk === item.patient_id
          );
          if (!responsibility) {
            patientsResponsibilityTypesList.push({
              patientPk: item.patient_id,
              responsibilityList: item.responsibility_list,
              flag: true
            })
          }
        }
      });
      // Copy existing procedure obj data to new procedure data obj
      for (var proc in tempProcedureDatanow) {
        tempProcedureData[tempIndex] = tempProcedureDatanow[proc];
        tempIndex += 1;
      }

      setResponsibilityDataList(patientsResponsibilityTypesList);
      setProcedureData(tempProcedureData);
      setOldResponsibility(responsibility_data)
      setResponsible_type(responsibility_data)
      setProcedureList(new_procedures.concat(proc_temp)); // concatenate existing procedures with newly added procedures
    }
  }



  const checkForError = (temp, apply_type, index, e) => {
    let value = e.target.value ? e.target.value : 0
    let paid_amount = parseFloat(temp[index].paid_amount);
    let allowed_amount = parseFloat(temp[index].allowed_amount);
    let copay = parseFloat(temp[index].copay ? temp[index].copay : 0);
    let co_insurance = parseFloat(temp[index].co_insurance ? temp[index].co_insurance : 0);
    let deductibles = parseFloat(temp[index].deductibles ? temp[index].deductibles : 0);

    let amount1 = parseFloat(allowed_amount - paid_amount);
    let amount2;
    if (apply_type == 'copay') {
      amount2 = parseFloat(value) + co_insurance + deductibles;

    }
    else if (apply_type == 'deductibles') {
      amount2 = parseFloat(value) + co_insurance + copay;

    }
    else if (apply_type == 'co_insurance') {
      amount2 = parseFloat(value) + copay + deductibles;

    }

    if (parseFloat(amount2.toFixed(2)) > parseFloat(amount1.toFixed(2))) {

      setError({
        ...error,
        [`copay${index}`]: true,
        [`co_insurance${index}`]: true,
        [`deductibles${index}`]: true
      })
    }
    else {

      setError({
        ...error,
        [`copay${index}`]: false,
        [`co_insurance${index}`]: false,
        [`deductibles${index}`]: false
      })
    }
  }

  const handleAdjustmentAmountChange = (e, adjstmntCode1, index, adjustment1or2) => {
    let value = String(e.target.value).trim().replace(/[^\-\d|\d(.\d+)]/g, '').substring(0, 9);
    // Convert to two decimal points when the input has more than 2 decimal points
    if (!isNaN(Number(value))) {
      let parts = String(value).split('.');
      if (parts.length > 1 && parts[1].length > 2) {
        value = parseFloat(value)?.toFixed(2);
      }
    }

    setProcedureData((prevState) => {
      // Get the row that need edit
      let procedureRowToEdit = { ...prevState[index] }; // Create a new object
      // Find the index of the  adjustmentItem that need edit
      const adjustmentEntryToEdit = procedureRowToEdit[adjustment1or2]?.findIndex((code) => code.id === adjstmntCode1.id);
      if (adjustmentEntryToEdit !== -1) {
        procedureRowToEdit[adjustment1or2][adjustmentEntryToEdit].amount = value;
      }
      // If the amount has been added to PR-1,2,3 codes then copy that value to copay, co-insurance and deductive respectively
      if (
        adjstmntCode1.label === 'PR-1' || adjstmntCode1.label === 'PR-2' || adjstmntCode1.label === 'PR-3'
      ) {
        switch (adjstmntCode1.label) {
          case 'PR-1':
            procedureRowToEdit.deductibles = value;
            break;
          case 'PR-2':
            procedureRowToEdit.co_insurance = value;
            break;
          case 'PR-3':
            procedureRowToEdit.copay = value;
            break;
          default:
            break;
        }
      }
      const newState = [...prevState]; // Create a new array
      newState[index] = procedureRowToEdit; // Update the new array
      return newState; // Return the new array
    });
  }


  // Handling data in Procedure data & update procedure data object
  function onHandleApply(e, index, apply_type) {
    let temp = JSON.parse(JSON.stringify(procedureData));
    if (apply_type == 'copay' || apply_type == 'co_insurance' ||
      apply_type == "deductibles") {
      checkForError(temp, apply_type, index, e)
    }
    let openingBalance = temp[index].opening_balance;
    let value = String(e.target.value).trim()
    .replace(/[^0-9.]/g, '')  // Remove all non-numeric and non-period characters
    .replace(/(\..*?)\./g, '$1')  // Remove all extra decimal points
    .replace(/(\.\d{2})\d*/g, '$1')  // Allow only two digits after the first decimal point
    .substring(0, 9);
     
if (apply_type ==="allowed_amount" && openingBalance <  Number(e.target.value)){
 setProcedureRowErrors((prev) => {
  prev[index].allowedAmount = i18n.t("payments.post_payments.insurancePayment.paymenMessages.allowedAmountVlidation");
  return prev;
 })
} else if ( procedureRowErrors[index].allowedAmount == i18n.t("payments.post_payments.insurancePayment.paymenMessages.allowedAmountVlidation")) {
  setProcedureRowErrors((prev) => {
    prev[index].allowedAmount = null;
    return prev;
   })
}

if (apply_type === "responsibility_type") {
      if(value == 5 && temp[index].closing_balance > 0)
      {
        showNotifyWindow('show', 'error', i18n.t('errorMessages.paidErrorMessage'));
        return;
      }
      
    }

    if (apply_type === "responsibility_type") {
      value = parseInt(value);
      // Resetting claim_status_id and claim_sub_status_id to null
      // whenever the responsibility type changes
      temp[index]["claim_status_id"] = null;
      temp[index]["claim_sub_status_id"] = null;

    } 

    if (apply_type === "claim_status_id") {
      value = parseInt(value)
      temp[index][apply_type] = value;
      temp[index]["claim_sub_status_id"] = null;
    }

    if (apply_type === "claim_sub_status_id") {
      value = parseInt(value)
      temp[index][apply_type] = value;
    }

    temp[index][apply_type] = value;

    if (isNaN(temp[index]["charges"] - temp[index]["allowed_amount"])) {
      setProcedureData(temp);
      setFlag(!flag);
    } else {
      if (apply_type === "allowed_amount") {
        temp[index]["adjustment_amount1"] =
          Number(temp[index]["opening_balance"] - temp[index]["allowed_amount"]).toFixed(2);
      }
      setProcedureData(temp);
      setFlag(!flag);
    }
  }

  function onResetPayment() {
    setSelectedPayer([])
    setInsurancePaymentVariables({
      payer: "",
      payment_date: "",
      paymentDate: "",
      reference_check_number: "",
      amount: "",
      payment_mode: "",
      card_type: null,
      level_adjustments: 0,
      adjustment_type: [],
      note: "",
    })
  }

  useEffect(() => {
    setProcedureRowErrors((prevState) => {
      let newErrors = [...prevState];
      for (let index = 0; index < procedureData?.length; index++) {
        newErrors[index] = {};
      }
      return newErrors;
    });

  }, [procedureData?.length]);

  function ProcedureRowErrorValidate(e, procedureRow, newState) {
    let updatedErrors = [...procedureRowErrors];
    let paidAmount = Number(Number(newState[procedureRow].paid_amount)?.toFixed(2)) || 0;
    let charges = Number(Number(newState[procedureRow].charges)?.toFixed(2)) || 0;
    let allowedAmount = Number(Number(newState[procedureRow].allowed_amount)?.toFixed(2)) || 0;
    let discount = charges - allowedAmount;

    // Allowed amount validation
    updatedErrors[procedureRow] = {
      ...updatedErrors[procedureRow],
      allowedAmount: allowedAmount > charges ?
        'Allowed amount cannot be greater than charges amount,' : ''
    };
    if (!updatedErrors[procedureRow].allowedAmount) {
      delete updatedErrors[procedureRow].allowedAmount;
    }

    // Paid amount validation
    updatedErrors[procedureRow] = {
      ...updatedErrors[procedureRow],
      paidAmount: (paidAmount > charges || paidAmount > allowedAmount) ?
        'Paid amount cannot be greater than charges amount or allowed amount.' : ''
    };
    if (!updatedErrors[procedureRow].paidAmount) {
      delete updatedErrors[procedureRow].paidAmount;
    }

    // Adjustment amount 1 validation
    let totalAdjustmentAmount1 = newState[procedureRow].adjustment_code1_data?.reduce((sum, adjustmentItem) => {
      // CON-253 Should not be considered as discount
      if (adjustmentItem.label?.toLowerCase() !== "co-253") {
        return sum + Number(adjustmentItem?.amount);
      } else {
        return sum;
      }
    }, 0) || 0;

    if (Number(Number(totalAdjustmentAmount1)?.toFixed(2)) > Number(Number(discount)?.toFixed(2))) {
      updatedErrors[procedureRow] = {
        ...updatedErrors[procedureRow],
        adjustment_amount1: newState[procedureRow].adjustment_code1_data?.reduce((errors, adjustmentItem) => {
          if (adjustmentItem.label?.toLowerCase() !== "co-253") {
            errors[adjustmentItem.id] = {
              id: adjustmentItem.id,
              message: 'Total Adjustment 1 amount should not be greater than Charge amount - Allowed amount.',
              label: adjustmentItem?.label
            };
          }
          return errors;
        }, {})
      };
    } else if (updatedErrors[procedureRow]?.adjustment_amount1) {
      newState[procedureRow].adjustment_code1_data?.forEach(adjustmentItem => {
        if (adjustmentItem.label?.toLowerCase() !== "co-253") {
          delete updatedErrors[procedureRow].adjustment_amount1[adjustmentItem.id];
        }
      });
    }


    // Adjustment amount 2 validation
    let totalAdjustmentAmount2 = newState[procedureRow].adjustment_code2_data?.reduce((sum, adjustmentItem) => sum + Number(adjustmentItem?.amount), 0) || 0;
    if (Number(Number(totalAdjustmentAmount2)?.toFixed(2)) > Number((allowedAmount - paidAmount)?.toFixed(2))) {
      updatedErrors[procedureRow] = {
        ...updatedErrors[procedureRow],
        adjustment_amount2: newState[procedureRow].adjustment_code2_data?.reduce((errors, adjustmentItem) => {
          errors[adjustmentItem.id] = {
            id: adjustmentItem.id,
            message: 'Total Adjustment 2 amount should not be greater than Allowed amount - Paid amount.',
            label: adjustmentItem?.label
          };
          return errors;
        }, {})
      };
    } else if (updatedErrors[procedureRow]?.adjustment_amount2) {
      newState[procedureRow].adjustment_code2_data?.forEach(adjustmentItem => {
        delete updatedErrors[procedureRow].adjustment_amount2[adjustmentItem.id];
      });
    }

    setProcedureRowErrors(updatedErrors);
  }

  function onBlurApply(e, index, adjustmentCodeId) {
    let temp = JSON.parse(JSON.stringify(procedureData));
    let proObj;
    for (var proc in procedureData) {
      if (parseInt(proc) === parseInt(index)) {
        proObj = procedureData[proc];
        break;
      }
    }
    let totalAdjustment2Amount = temp[index].adjustment_code2_data?.reduce((totalAdjustmentAmnt, adjustmentItem) => {
      return totalAdjustmentAmnt + (Number(adjustmentItem?.amount) || 0);
    }, 0);
    temp[index]['closing_balance'] = totalAdjustment2Amount;
    const patientPk = proObj.patient_id;
    const dataInLocalState = responsibilityDataList.find((item) => item.patientPk === patientPk);
    let responsibilityID;
    let responsibilityStatusFlag = false;
    if (dataInLocalState) {
      if (temp[index]["copay"] > 0 || temp[index]["deductibles"] > 0 || temp[index]["co_insurance"] > 0) {
        if (responsible_type[index] === 2) {
          let responsibilityData = dataInLocalState?.responsibilityList?.filter((item) => item.name === 'Tertiary');
          if (responsibilityData?.length > 0) {
            responsibilityID = responsibilityData[0].id
            responsibilityStatusFlag = true;
          } else {
            responsibilityID = dataInLocalState?.responsibilityList?.filter((item) => item.name === 'Patient')[0]?.id;
            responsibilityStatusFlag = true;
          }
        }
        else if (responsible_type[index] === 4) {
          let responsibilityData = dataInLocalState?.responsibilityList?.filter((item) => item.name === 'Patient');
          if (responsibilityData?.length > 0) {
            responsibilityID = responsibilityData[0].id
            responsibilityStatusFlag = true;
          }
        }
        else if (responsible_type[index] === 3) {
          let responsibilityData = dataInLocalState?.responsibilityList?.filter((item) => item.name === 'Patient');
          if (responsibilityData?.length > 0) {
            responsibilityID = responsibilityData[0].id
            responsibilityStatusFlag = true;
          }
        }
        else if (responsible_type[index] === 1) {
          let responsibilityData = dataInLocalState?.responsibilityList?.filter((item) => item.name === 'Secondary');
          if (responsibilityData?.length > 0) {
            responsibilityID = responsibilityData[0].id
            responsibilityStatusFlag = true;
          }
          else {
            let responsibilityData = dataInLocalState?.responsibilityList?.filter((item) => item.name === 'Patient');
            if (responsibilityData?.length > 0) {
              responsibilityID = responsibilityData[0].id
              responsibilityStatusFlag = true;
            }
          }
        }
        else if (responsible_type[index] === 5) {
          if (temp[index]['opening_balance'] <= 0 ) {
            responsibilityID = 5;
          } else {
          let responsibilityData = dataInLocalState?.responsibilityList?.filter((item) => item.name === 'Secondary');
          if (responsibilityData?.length > 0) {
            responsibilityID = responsibilityData[0].id
            responsibilityStatusFlag = true;
          }
          else {
           let responsibilityData = dataInLocalState?.responsibilityList?.filter((item) => item.name === 'Patient');
            if (responsibilityData?.length > 0) {
              responsibilityID = responsibilityData[0].id
              responsibilityStatusFlag = true;
            }
          }
          }
        }
      }
      else if (e?.target?.name == 'paid_amount') {
        if (parseFloat(temp[index].allowed_amount) === parseFloat(temp[index].paid_amount) && temp[index].allowed_amount != 0 && temp[index].paid_amount!=0  ) {
          let responsibilityData = dataInLocalState?.responsibilityList?.filter((item) => item.name === 'Paid');
          if (responsibilityData.length > 0) {
            responsibilityID = responsibilityData[0].id
            responsibilityStatusFlag = true;
          }
        }
        else {
          responsibilityID = oldResponsibility[index]
        }

      }

      else if (temp[index].allowed_amount !== temp[index].paid_amount) {
        if (temp[index]?.adjustment_code1_data?.length > 0) {
          let dataValue = temp[index]?.adjustment_code1_data?.filter((item => item.label == "CO-253"));
          if (dataValue.length > 0 && dataValue[0].label == 'CO-253') {
            let responsibilityData = dataInLocalState?.responsibilityList?.filter((item) => item.name === 'Paid');
            if (responsibilityData.length > 0) {
              responsibilityID = responsibilityData[0].id
              responsibilityStatusFlag = true;
            }
          }
          else {
            responsibilityID = oldResponsibility[index]
          }
        }
        else {
          responsibilityID = oldResponsibility[index]
        }
      }
      else if (temp[index].allowed_amount === temp[index].paid_amount && temp[index].allowed_amount != 0 && temp[index].paid_amount != 0){
        let responsibilityData = dataInLocalState?.responsibilityList?.filter((item) => item.name === 'Paid');
        if (responsibilityData.length > 0) {
          responsibilityID = responsibilityData[0].id
          responsibilityStatusFlag = true;
        }
        else {
          responsibilityID = oldResponsibility[index]
        }
      }
      else {
        responsibilityID = oldResponsibility[index]
      }
      temp[index]["responsibility_type"] = responsibilityID;
    }

    setProcedureData(temp);
    setFlag(!flag);

    //error validation
    ProcedureRowErrorValidate(e, index, temp, adjustmentCodeId)
    if (responsibilityStatusFlag) {
      temp[index]['claim_status_id'] = null
      temp[index]['claim_sub_status_id'] = null
    } else {
      responsibilityStatusFlag = false
    }
  }

  useEffect(() => {

     // Function to safely parse and handle invalid numeric values
     const safeDecimal = (value) => {
      try {
        return new Decimal(value);
      } catch (error) {
        return new Decimal(0); // Fallback to 0 if there's an invalid value (like just a period)
      }
    };

    // Calculate Applied & Unapplied amount when data in payment procedure obj gets updated
    let totApplied = new Decimal(0);
    procedureData.forEach((element) => {
      const paidAmount = safeDecimal(element.paid_amount || 0); // Ensure valid number
      totApplied = totApplied.plus(paidAmount);
    })
    setAppliedAmount(totApplied.isNaN() || totApplied === null || totApplied === undefined ? "0.00" : totApplied.toFixed(2));
    if (insurancePaymentData && insurancePaymentData["total_amount"] !== undefined) {
      let unAppliedAmount = safeDecimal(insurancePaymentData["total_amount"]).minus(totApplied);
      unAppliedAmount = Object.is(unAppliedAmount.toNumber(), -0) ? 0 : unAppliedAmount.toFixed(2);
      setUnAppliedAmount(unAppliedAmount);
    }
  }, [flag, procedureData]);

  function listCliamsofPatient(queryType, data) {
    let page = 0;
    let query = "?page_size=" + DEFAULT_PAGING_SIZE + "&page=" + page;
    if (queryType === "patient") {
      const paymentDate = "&payment_dos=" + insurancePaymentVariables.payment_date;
      query += "&patient_pk=" + data + paymentDate;
      setSearchQuery(query);
    } else if (queryType === "advanced") {
      let dataObj = claimSearchVariables;

      const formatDate = (date) => {
        return date instanceof Date ? format(date, "yyyy-MM-dd") : "";
      };

      const addQueryParam = (key, value) => {
        if (value !== "") {
          query += `&${key}=${value}`;
        }
      };

      query += "&filter=true";
      addQueryParam("claim_status_pk", dataObj["claim_status"]);
      addQueryParam("claim_type", dataObj["claim_type"]);
      addQueryParam("dos_from", formatDate(dataObj["claim_date_of_service_from"]));
      addQueryParam("dos_to", formatDate(dataObj["claim_date_of_service_to"]));
      addQueryParam("submitted_from_date", formatDate(dataObj["claim_submitted_date_from"]));
      addQueryParam("submitted_to_date", formatDate(dataObj["claim_submitted_date_to"]));
      addQueryParam("res_type_pk", dataObj["responsibility_type"]);

      setSearchQuery(query);
    } else {
      query = data;
    }
    setShowLoadingBar(true);
    const result = service.ListInsurancePaymentClaims(query);
    result.then((response) => {
      setClaimList(response.data);
      if (response.data.length === 1) {
        listProceduresOfClaim(response.data[0].id);
        setSearchModalOpen(false);
      } else if (response.data.length > 1) {
        setSearchModalOpen(true);
        setHeader(
          i18n.t("payments.post_payments.insurancePayment.searchResults")
        );
      }
    }).catch(() => {
      showNotifyWindow('show', 'error', i18n.t('errorMessages.commonError'));
    });
    setShowLoadingBar(false);
  }

  function CheckPaymentExists(post_type) {
    setShowLoadingBar(true)
    if (!insurancePaymentVariables.payer || insurancePaymentVariables.payer == '' || insurancePaymentVariables.payer == null) {
      setShowLoadingBar(false)
      showNotifyWindow('show', 'error', i18n.t('errorMessages.payer_error'))
      return;
    }
    if (insurancePaymentVariables.level_adjustments > 0 &&
      insurancePaymentVariables.adjustment_type.length == 0) {
      setShowLoadingBar(false)
      showNotifyWindow('show', 'error',
        "Please select adjustment type")
      return
    }
    if (
      insurancePaymentVariables["paymentDate"] &&
      insurancePaymentVariables["reference_check_number"] &&
      insurancePaymentVariables["amount"] &&
      insurancePaymentVariables["payment_mode"] &&
      ((insurancePaymentVariables["payment_mode"] ===
        PAYEMNT_MODES_CREDITCARD_ID &&
        insurancePaymentVariables["card_type"]) ||
        insurancePaymentVariables["payment_mode"] !==
        PAYEMNT_MODES_CREDITCARD_ID)
    ) {
      let data = {
        payer: insurancePaymentVariables["payer"],
        reference_check_number:
          insurancePaymentVariables["reference_check_number"],
        amount: insurancePaymentVariables["amount"],
        payment_date: insurancePaymentVariables["payment_date"],
        payment_type: "insurance",
      };
      const result = service.CheckForPaymentExists(data);
      result.then((response) => {
        setShowLoadingBar(false)
        if (response.data.payment_exists) {
          setShowAlertModalWindow(true);
          setAlertMessage("Payment Already Exists");
        } else if (post_type == "credit") {
          setShowPostAsCreditModal(true);
          setHeader(i18n.t("payments.post_payments.postAsCreditModalHeader"));
        } else {
          onSavePayment();
        }
      });
    } else {
      setShowLoadingBar(false)
    }
  }

  function paymentPostManually() {
    CheckPaymentExists("manually");
  }

  function resetForm() {
    setHeader("");
    setShowPostAsCreditModal(false);
  }


  // search modal functions
  function resetFormSearch() {
    setHeader("");
    setSearchOption("claim_pk");
    setSearchText("");
    setSearchModalOpen(false);
  }
  function openSearchModal() {
    listCliamsofPatient("advanced");
  }
  function onReset() {
    setClaimSearchVariables({
      claim_id: "",
      patient_id: "",
      claim_status: "",
      responsibility_type: "",
      claim_type: "",
      claim_date_of_service_from: "",
      claim_date_of_service_to: "",
      claim_date_type: 1,
      claim_submitted_date_from: "",
      claim_submitted_date_to: "",
      claim_submitted_date_type: 1,
      reversal_payent: false,
    });
  }
  function showAdvancedOption() {
    setShowResetButton(true)
    document.getElementById("hidingAdvancedOption").classList.toggle("show");
    if (
      document.getElementById("hidingAdvancedOptionSearch").style.display ==
      "none"
    ) {
      document.getElementById("hidingAdvancedOptionSearch").style.display = "";
    } else {
      document.getElementById("hidingAdvancedOptionSearch").style.display =
        "none";
    }
  }

  // patient search
  const listPatientDropdown = (query) => {
    if (query) query += "&practice_pk=" + practicePK;
    else query = "practice_pk=" + practicePK;
    query = query + `&insurances=[${insurancePaymentData.payer}]`;
    const result = service.GetListPatientDropdown(query);
    result.then((response) => {
      setPatientDropdown(response.data);
    });
  };

  function onSearchInsurances(query) {
    listInsuranceDropdown(query);
  }

  function listInsuranceDropdown(query) {
    getMasterClaims(query);
  }

  function getMasterClaims(query) {
    query = query + `&insurances=[${insurancePaymentData.payer}]`;
    query = query + '&filter=1';
    const result = service.ListClaimsDropDown(
      query,
      claimSearchVariables.patient_id,
      getStorage("practice")
    );

    result.then((response) => {
      setMasterClaims(response.data);
    });
  }

  function onHandleClaimIDChange(e) {
    if (e[0]) {
      setClaimSearchVariables({
        ...claimSearchVariables,
        claim_id: e[0].id,
      });
      listProceduresOfClaim(e[0].id);
    } else {
      setClaimSearchVariables({
        ...claimSearchVariables,
        claim_id: "",
      });
    }
  }

  function showEditModalWindow() {
    setHeader(i18n.t("payments.post_payments.editPayment"));
    setShowEditPaymentModal(true);
    setSelectedPayer([{
      "id": insurancePaymentData.id,
      "name": insurancePaymentData.payer_name,
      "alert": ""
    }])
  }


  function resetEditPaymentForm() {
    setHeader("");
    setShowEditPaymentModal(false);
    getPaymentData()
  }
  // Handle change for Editing a payment basic data
  function onHandleChangeEditPayment(e) {
    let name = e.target.name;
    let value = e.target.value;
    if (["level_adjustments", "amount"].includes(name)) {
      value = String(e.target.value)
        .trim()
        .replace(/[^\d.-]/g, "") // Allow digits, hyphen, and period
        .replace(/(?<=\d)-/g, "") // Remove hyphen if not at the start
        .replace(/(\.\d{0,1}\d{0,1})\d*/, "$1") // Limit decimal part to two digits or less
        .substring(0, 9);
    }
    setEditPaymentVariables({
      ...editPaymentVariables,
      [name]: value,
    });
    if (name === 'payment_mode') {
      setEditPaymentVariables({
        ...editPaymentVariables, ["reference_check_number"]: "",
        ["amount"]: "", [name]: value,
      });

    }
  }

  function onHandleChangeEditPaymentDate(value, name) {
    setEditPaymentVariables({
      ...editPaymentVariables,
      [name]: value
    });
    // }
  }

  function handleSearchPayerEdit(e) {
    let label = ""
    let selected = [];
    let data = { ...editPaymentVariables }
    if (Array.isArray(e) && e.length > 0) {
      label = e[0].id;
      selected = e;
    }
    data.payer = label;
    setEditPaymentVariables(data)
    setSelectedPayer(selected)
  }

  function onAlertOk() {
    setShowAlertModalWindow(false);
  }


  function postClaim(e, claimPK) {
    listProceduresOfClaim(claimPK);
    resetFormSearch();
  }

  function checkAmountValue() {
    for (const key in error) {
      if (error[key] == true) {
        return true
      }
    }
    return false
  }

  const createApiRequestData = (procedures) => {
    let requestdata = [];
    let adjustment1 = [];
    let adjustment2 = [];
    let data;
    for (let item in procedures) {
      adjustment1 = [];
      adjustment2 = [];
      if (procedures[item]?.adjustment_code1_data?.length > 0) {
        for (let code1 in procedures[item]?.adjustment_code1_data) {
          const adjustment1Data = {
            "code": procedures[item]?.adjustment_code1_data[code1].label,
            "amount": procedures[item]?.adjustment_code1_data[code1].amount,
            "id": procedures[item]?.adjustment_code1_data[code1].id
          }

          adjustment1.push(adjustment1Data)
        }
      }
      if (procedures[item]?.adjustment_code2_data?.length > 0) {
        for (let code2 in procedures[item]?.adjustment_code2_data) {
          const adjustment1Data = {
            "code": procedures[item]?.adjustment_code2_data[code2].label,
            "amount": procedures[item]?.adjustment_code2_data[code2].amount,
            "id": procedures[item]?.adjustment_code2_data[code2].id
          }

          adjustment2.push(adjustment1Data)
        }
      }
      data = {
        "payment": procedures[item].payment,
        "claim": procedures[item].claim,
        "procedure": procedures[item].id,
        "allowed_amount": procedures[item].allowed_amount ? procedures[item].allowed_amount : 0,
        "paid_amount": procedures[item].paid_amount ? procedures[item].paid_amount : 0,
        "opening_balance": procedures[item].opening_balance ? procedures[item].opening_balance : 0,
        "closing_balance": procedures[item].closing_balance ? procedures[item].closing_balance : 0,
        "charges": procedures[item].charges ? procedures[item].charges : 0,
        "remark_code": procedures[item].remark_code,
        "responsibility_type": procedures[item].responsibility_type,
        "copay": procedures[item].copay ? procedures[item].copay : 0,
        "co_insurance": procedures[item].co_insurance ? procedures[item].co_insurance : 0,
        "deductibles": procedures[item].deductibles ? procedures[item].deductibles : 0,
        "pay_procedure_status": procedures[item].pay_procedure_status,
        "entry_reversed": procedures[item].entry_reversed,
        'save_procedure_id': procedures[item].save_procedure_id ? procedures[item].save_procedure_id : "",
        "adjustments": {
          "adjustment1": adjustment1,
          "adjustment2": adjustment2
        },
        "claim_status_id": procedures[item].claim_status_id,
        "claim_sub_status_id": procedures[item].claim_sub_status_id

      }

      requestdata.push(data)
    }

    return requestdata;
  }

  const checkResponsibility = (procedureData) => {
    for (let item in procedureData) {
      if (procedureData[item]?.responsibility_type == null) {
        return true
      }
    }
    return false;
  }

  async function onSavePaymentProcedures() {
    setShowLoadingBar(true);
    for(let insData of procedureData){
      if (!insData.claim_status_id){
        setShowLoadingBar(false);
        return;
      }
      if (insData.claim_status_id && (claimSubStatusList?.filter(item => item.parent_claim_status_ids?.includes(insData?.claim_status_id)).length > 0 ) && !insData.claim_sub_status_id ) {
        showNotifyWindow("show", "error", "please select claim sub status before saving")
        setShowLoadingBar(false);
        return;
      }
    }
    let paymentDatePriorCheck = procedureList.map((item) => insurancePaymentData.payment_date > item.dos_from).includes(false)
    let amountCheck = checkAmountValue()
    let responsebilityCheck = checkResponsibility(procedureData)
    if (paymentDatePriorCheck) {
      showNotifyWindow('show', 'error', i18n.t(
        "payments.post_payments.insurancePayment.paymenMessages.paymentDateAlert"
      ))
      setShowLoadingBar(false);
    } else {
      let procedures = [];
      for (var proc in procedureData) {
        if (parseFloat(procedureData[proc]["paid_amount"]) > 0 && parseFloat(procedureData[proc]["allowed_amount"]) > 0) {
          if (
            parseFloat(procedureData[proc]["paid_amount"]) >
            parseFloat(procedureData[proc]["allowed_amount"])
          ) {
            setShowAlertModalWindow(true);
            setAlertMessage(
              i18n.t(
                "payments.post_payments.insurancePayment.paymenMessages.paidAmountValidation"
              )
            );
            setShowLoadingBar(false);
            return;
          }
        }

        if (
          procedureData[proc]["paid_amount"] !== 0 ||
          procedureData[proc]["adjustment_amount1"] !== 0 ||
          procedureData[proc]["adjustment_amount2"] !== 0
        )
          procedures.push(procedureData[proc]);
      }
      if (unAppliedAmount < 0) {
        setShowAlertModalWindow(true);
        setAlertMessage(
          "Total applied amount should be less than or equal to total payment amount."
        );
      } else if (amountCheck) {
        setShowAlertModalWindow(true);
        setAlertMessage(
          i18n.t(
            "payments.post_payments.insurancePayment.paymenMessages.copayValidation"
          )
        );
      }
      else if (responsebilityCheck) {
        setShowAlertModalWindow(true);
        setAlertMessage(
          i18n.t(
            "payments.post_payments.insurancePayment.paymenMessages.emptyPaymentResponsibleType"
          )
        );
      }
      else {
        const apiRequestData = createApiRequestData(procedureData);
        const response = await service.SaveInsurancePaymentProcedures({ "current_practice_id": getStorage("practice"), "payment_pk": props.paymentPK, "procedures_data": apiRequestData },);
        if (response?.data?.status === "success") {
          props.showNotifyWindow("show", "success", "Payment Updated...");
          setTimeout(() => {
            if (props.updatePayment) {
              props.setSelectedEditPaymentPK("");
              props.setActiveTab("viewPayments");
            } else window.location.reload();
          }, 500);
        } else {
          if (response.data?.message === 'invalid_responsibility_type') {
            showNotifyWindow("show", "error", `Claim status selected not matching with the Priority on Procedure ${response.data?.data?.cpt ?? ""}`);
          } else if (response.data?.message === 'cpt_or_claim_status_missing') {
            showNotifyWindow("show", "error", `Procedure or the claim status is missing in a procedure row.`);
          } else {
            props.showNotifyWindow("show", "error", "An error occurred while posting the payment.");
          }
        }
      }

      setShowLoadingBar(false);
    }
  }

  function onCancelPaymentProcedures() {
    if (props.onClosePayment) {
      props.onClosePayment();
    } else {
      history.push(ROUTE_VIEW_PAYMENTS)
    }
  }

  function SetPaymentVariablesData(data) {
    setEditPaymentVariables({
      custom_payment_id: data.custom_payment_id,
      payment_date: data.payment_date,
      reference_check_number: data.reference_check_number,
      card_type: data.card_type,
      amount: data.amount,
      payment_mode: data.payment_mode,
      note: data.note,
      payer: data.payer,
      level_adjustments: data.level_adjustments,
      adjustment_type: Array.isArray(data.adjustment_type) ? [...data.adjustment_type] : [data.adjustment_type]
    })
  }

  function onSaveFormData(e) {
    e.preventDefault();
    let payment_allowed_amount =
      editPaymentVariables["amount"] -
      editPaymentVariables["level_adjustments"];

    if (!editPaymentVariables.payment_date || !editPaymentVariables.payment_mode || !editPaymentVariables.reference_check_number || !editPaymentVariables.amount || !editPaymentVariables.payer) {
        return;
    }

    if (editPaymentVariables.payment_date) {
      const dateObject = new Date(editPaymentVariables.payment_date);
      const today = new Date();
      // today.setHours(0, 0, 0, 0);

      if (isNaN(dateObject) || dateObject > today) {
        showNotifyWindow('show', 'error', i18n.t("payments.post_payments.insurancePayment.paymenMessages.paymentDateValidation"));
        return;
      }
    }

    if (appliedAmount > payment_allowed_amount) {
      setShowAlertModalWindow(true);
      setAlertMessage(
        "Total posted amount should be greater than Total applied amount"
      );
    } else {
      const result = service.UpdateInsurancePayment(
        props.paymentPK,
        {
          ...editPaymentVariables,
          payment_date: formatDateUS(editPaymentVariables.payment_date),
          level_adjustments: editPaymentVariables.level_adjustments ? editPaymentVariables.level_adjustments : DEFAULT_ADJUSTMENT_VALUE,
          payer: editPaymentVariables.payer ?? null,
          amount: editPaymentVariables['amount'] ? editPaymentVariables['amount'] : insurancePaymentData.amount
        }
      );
      result.then((response) => {
        setInsurancePaymentData(response.data);
        SetPaymentVariablesData(response.data);
        setShowEditPaymentModal(false);
      });
    }
  }

  useEffect(() => {
    setUnAppliedAmount(insurancePaymentData["total_amount"] - appliedAmount);
  }, [editPaymentVariables]);

  function removeProcedure(e, removeIndex) {
    let temp = procedureData;
    let tempProcedureData = [];
    let tempIndex = 0;
    // Remove item at index, from payment procedure object
    for (var proc in temp) {
      if (parseInt(removeIndex) !== parseInt(proc)) {
        tempProcedureData[tempIndex] = temp[proc];
        tempIndex += 1;
      }
      if (
        parseInt(proc) === parseInt(temp[removeIndex]["actual_entry_index"])
      ) {
        tempProcedureData[proc]["entry_reversed"] = false;
      }
    }
    setProcedureData(tempProcedureData);
    let new_list = [];
    // Remove item at index, from Procedure list
    procedureList.map((item, index) => {
      if (index !== removeIndex) new_list.push(item);
    });
    setProcedureList(new_list);
  }

  function onHandleChangeSearchOption(e) {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "searchOption") setSearchOption(value);
    else setSearchText(value);
  }

  function onClickSearchClaim() {
    let query = searchQuery;
    query += "&search_option=" + searchOption + "&search_text=" + searchText;
    listCliamsofPatient("searchInAdvanced", query);
  }

  function reversePayment(e, index) {
    let reverseIndex = index + 1;
    let adjustment1 = [];
    let adjustment2 = [];
    procedureData[index].entry_reversed = true;
    if (procedureData[index].adjustment_code1_data.length > 0) {
      for (let code1 in procedureData[index].adjustment_code1_data) {
        const code1Data = {
          ...procedureData[index].adjustment_code1_data[code1],
          amount: procedureData[index].adjustment_code1_data[code1].amount * -1
        }
        adjustment1.push(code1Data);
      }
    }
    if (procedureData[index].adjustment_code2_data.length > 0) {
      for (let code2 in procedureData[index].adjustment_code2_data) {
        const code1Data = {
          ...procedureData[index].adjustment_code2_data[code2],
          amount: procedureData[index].adjustment_code2_data[code2].amount * -1
        }
        adjustment2.push(code1Data);
      }
    }
    const newEntry = {
      "payment": procedureData[index].payment,
      "claim": procedureData[index].claim,
      "procedure": procedureData[index].procedure,
      "dos_from": procedureData[index].dos_from,
      "custom_claim_id": procedureData[index].custom_claim_id,
      "allowed_amount": procedureData[index].allowed_amount ? procedureData[index].allowed_amount * -1 : "0.00",
      "paid_amount": procedureData[index].paid_amount ? procedureData[index].paid_amount * -1 : "0.00",
      "remark_code": procedureData[index].remark_code,
      "opening_balance": procedureData[index].opening_balance ? procedureData[index].opening_balance * -1 : "0.00",
      "closing_balance": procedureData[index].closing_balance * -1 ? procedureData[index].closing_balance * -1 : "0.00",
      "charges": procedureData[index].charges ? procedureData[index].charges * -1 : "0.00",
      "adjustment_code1_data": adjustment1,
      "adjustment_code2_data": adjustment2,
      "copay": parseInt(procedureData[index].copay) != 0 ? procedureData[index].copay * -1 : "0.00",
      "co_insurance": parseInt(procedureData[index].co_insurance) != 0 ? procedureData[index].co_insurance * -1 : "0.00",
      "deductibles": parseInt(procedureData[index].deductibles) != 0 ? procedureData[index].deductibles * -1 : "0.00",
      "pay_procedure_status": 1,
      "selectedRemarkCodes": procedureData[index].selectedRemarkCodes,
      "id": procedureData[index].id,
      "entry_reversed": true,
      "receipts": procedureData[index].receipts ? procedureData[index].receipts * -1 : "0.00"
    }

    const patientPk = procedureData[index].patient_id;
    let responsibilityID;
    const dataInLocalState = responsibilityDataList.find((item) => item.patientPk === patientPk);
    if (procedureData[index].responsibility_type == 2) {
      let responsibilityData = dataInLocalState?.responsibilityList?.filter((item) => item.name === 'Primary');
      if (responsibilityData.length > 0) {
        responsibilityID = responsibilityData[0].id
      }
    }
    else if (procedureData[index].responsibility_type == 3) {
      let responsibilityData = dataInLocalState?.responsibilityList?.filter((item) => item.name === 'Secondary');
      if (responsibilityData.length > 0) {
        responsibilityID = responsibilityData[0].id
      }
    }
    else if (procedureData[index].responsibility_type == 4) {
      responsibilityID = procedureData[index].previous_responsibility_type
    }

    else if (procedureData[index].responsibility_type == 5) {
      responsibilityID = procedureData[index].previous_responsibility_type
    }

    newEntry["responsibility_type"] = responsibilityID
    procedureData.splice(reverseIndex, 0, newEntry)
    setFlag(!flag)
  }


  function reverseAllInsurancePayments(e, index, item) {
    // To reverse all payments against a procedure multiply values with -1.
    const result = service.GetInsurancePaymentsData(item.id);
    result.then((response) => {
      let temp = procedureData;
      if (temp[index]["pay_procedure_status"] === 2) {
        temp[index]["pay_procedure_status"] = 0;
        temp[index]["paid_amount"] = 0;
        temp[index]["adjustment_amount1"] = 0;
        temp[index]["adjustment_amount2"] = 0;
      } else {
        temp[index]["pay_procedure_status"] = 2;
        temp[index]["paid_amount"] = response.data["paid_amount_sum"] * -1;
        temp[index]["adjustment_amount1"] = response.data["adjust1_sum"] * -1;
        temp[index]["adjustment_amount2"] = response.data["adjust2_sum"] * -1;
      }
      setProcedureData(temp);
      setTimeout(() => {
        onBlurApply("", index);
      }, 100);
    });
  }

  function claimRedirection(e, item) {
    let openedPKs = false;
    const result = service1.GetSelectedTabs("claims", getStorage("practice"));
    result.then((response) => {
      openedPKs =
        response.data && response.data.opened_tab_pks
          ? response.data.opened_tab_pks
          : [];
      let allowedPk = ["search", "last10"];
      allowedPk.forEach((items) => {
        let index = openedPKs.indexOf(items);
        if (index !== -1) {
          openedPKs.splice(index, 1);
        }
      });
      if (
        openedPKs &&
        Array.isArray(openedPKs) &&
        openedPKs.length >= MAX_OPEN_TABS_CLAIMS &&
        !openedPKs.includes(item.claim_id)
      ) {
        showNotifyWindow(
          "show",
          "error",
          i18n.t("errorMessages.max_claim_tabs")
        );
      } else {
        let redirect_item = {
          pk: item.claim,
          claim_id: item.custom_claim_id,
          type: "claims",
          action: "add",
          practice_pk: getStorage("practice"),
        };
        const result1 = service1.AddRemoveSelectedTab(redirect_item);
        result1.then((response1) => {
          if (response1.status === 200 || response1.data === 201)
            window.open(ROUTE_SEARCH_CLAIMS, "_blank");
        });
      }
    });
  }

  function selectOnTab(e) {
    if (e.key === "Tab") {
      document.querySelector("a.dropdown-item.active")?.click();
    }
  }

  function handleOPtions(e, name) {
    if (!e) {
      if (name == "adjustment_1_codes_list") {
        setAdjustment_1_codes_list([]);
      } else if (name == "adjustment_2_codes_list") {
        setAdjustment_2_codes_list([]);
      }
    }
  }

  /**
   * To display sum of all PR Amounts - Not completed
   * @param {*} deductible 
   * @param {*} coInsurance 
   * @param {*} coPay 
   */
  const updatePRTotal = (deductible, coInsurance, coPay) => {
    setPrTotal(deductible + coInsurance + coPay)
  }

 
  const handleResponsibilityTypeClick = (procedure) => {
    setIsFetchingResponsibilityData(true);
    const procedureRowItem = procedureData.find((item) => item.claim === procedure.claim);
    const patientPk = procedureRowItem.patient_id;
    const dataInLocalState = responsibilityDataList.find((item) => item.patientPk === patientPk && item.flag === true);
    if (dataInLocalState) {
      setResponsibilityData(dataInLocalState.responsibilityList);
      setIsFetchingResponsibilityData(false);
      return;
    }
    const claimPk = procedureRowItem.claim_id;
    if (patientPk) {
      const result = service.GetResponsibilityType(patientPk, claimPk);
      result.then((response) => {
        if (response.data) {
          setResponsibilityData(response.data.responsibility_list);
          setResponsibilityDataList([...responsibilityDataList, {
            patientPk: patientPk,
            responsibilityList: response.data.responsibility_list,
            flag: true
          }])
        } else if (response.status === 401) { // To handle session out issue
          history.push(LOGIN_ROUTE)
        }
      }).catch(() => {
        showNotifyWindow("show", "error", i18n.t("errorMessages.commonError"));
      })
    }
    setTimeout(() => {
      setIsFetchingResponsibilityData(false);
    }, 1300);
  }

  const handleSearchPayer = (e) => {
    let label = ""
    let selected = [];
    let data = { ...insurancePaymentVariables }
    if (Array.isArray(e) && e.length > 0) {
      label = e[0].id;
      selected = e;
    }
    data.payer = label;
    setInsurancePaymentVariables(data);
    setSelectedPayer(selected)
  };
  const onSearchPayer = (searchValue) => {
    getPayerData(DEFAULT_PAGING_SIZE, activePage, searchValue);
  }


  return (
    <React.Fragment>
      <div className="col-md-12">
        <Notify
          showNotify={showNotify}
          setShowNotify={setShowNotify}
          notifyDescription={notifyDescription}
          setNotifyType={setNotifyType}
          setNotifyDescription={setNotifyDescription}
          notifyType={notifyType}
        />
        <div className={props.updatePayment ? "postPayment" : ""}>
          <div className="">
            <Form autoComplete="off">
              {!props.paymentPK && (
                <div className='insurancePayment'>
                  <div className="row">
                    <div className="col-3">
                      <AsyncTypeInput
                        labelKey="name"
                        class_name={insurancePaymentVariables.payer == "" ? "input-error" : ""}
                        name="payer"
                        minLength={1}
                        placeholder={""}
                        onValueChange={(e) => handleSearchPayer(e, "payer")}
                        options={payersList}
                        onSearch={onSearchPayer}
                        id={"payer"}
                        label={i18n.t(
                          "payments.post_payments.insurancePayment.payer"
                        )}
                        clearButton={true}
                        selected={selectedPayer}
                      />
                    </div>
                    <div className="col-3">
                      <CalendarInput
                        name="paymentDate"
                        maxDate={new Date()}
                        required={true}
                        selected={insurancePaymentVariables.paymentDate}
                        onValueChange={(e) => onHandleDate(e, "paymentDate")}
                        label={i18n.t(
                          "payments.post_payments.insurancePayment.payment_date_EFT"
                        )}
                      />
                    </div>
                    <div className="col-3">
                      <div className="form-group">
                        <TextInput
                          name="reference_check_number"
                          required={true}
                          value={
                            insurancePaymentVariables.reference_check_number
                          }
                          onValueChange={onHandleChange}
                          label={i18n.t(
                            "payments.post_payments.insurancePayment.payment_reference"
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <CurrencyInputs
                        name="amount"
                        value={insurancePaymentVariables.amount}
                        onValueChange={onHandleChange}
                        label={i18n.t(
                          "payments.post_payments.insurancePayment.payment_amount"
                        )}
                        defaultClassName={
                          insurancePaymentVariables.amount === "" ? "input-error" : ""
                        }
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3">
                      <SelectInput
                        name="payment_mode"
                        required={true}
                        data={INSURANCEPAYMENTMODES}
                        value={insurancePaymentVariables.payment_mode}
                        onValueChange={onHandleChange}
                        label={i18n.t(
                          "payments.post_payments.insurancePayment.payment_mode"
                        )}
                      />
                    </div>
                    {insurancePaymentVariables.payment_mode ===
                      PAYEMNT_MODES_CREDITCARD_ID && (
                        <div className="col-3">
                          <SelectInput
                            name="card_type"
                            required={true}
                            data={CREDIT_CARD_TYPES}
                            value={insurancePaymentVariables.card_type}
                            onValueChange={onHandleChange}
                            label={i18n.t("payments.post_payments.credit_card")}
                            showCreditCardIcons={true}
                            className={
                              insurancePaymentVariables.creditCard
                                ? "input-error"
                                : ""
                            }
                          />
                        </div>
                      )}
                    <div className="col-3 ">
                      <div className='form-group'>
                        <TextInput
                          name="level_adjustments"
                          value={insurancePaymentVariables.level_adjustments}
                          onValueChange={onHandleChange}
                          label={i18n.t(
                            "payments.post_payments.insurancePayment.payment_level_adjustments"
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <MaterialMultiselect name="adjustment_type" required={insurancePaymentVariables.level_adjustments > 0 ? true : false} value={insurancePaymentVariables.adjustment_type} onValueChange={onHandleChange}
                        options={adjustmentTypes} label={i18n.t("payments.post_payments.insurancePayment.adjustment_type")}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <TextAreaInput
                        name="note"
                        value={insurancePaymentVariables.note}
                        onValueChange={onHandleChange}
                        rows={3}
                        label={i18n.t(
                          "payments.post_payments.insurancePayment.payment_note"
                        )}
                      />
                    </div>
                    <div className="col-6 justify-right padding-top70">
                      <div className="padding-left15">
                        <CommonButton
                          variant="outlined"
                          onClick={onResetPayment}
                          label={i18n.t("payments.post_payments.buttons.reset")}
                        />
                      </div>
                      <div className="padding-left15">
                        <CommonButton
                          variant="contained"
                          onClick={paymentPostManually}
                          label={i18n.t(
                            "payments.post_payments.insurancePayment.buttons.post_payment"
                          )}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row justify-right">
                    &nbsp;
                  </div>
                </div>
              )}
              {props.paymentPK ? (
                <>
                  <div style={{ marginLeft: 20, marginRight: 20 }}>
                    <table className='postPaymentTable'>
                      <tr>
                        <td>
                          <span className="paymentFormText">
                            {i18n.t(
                              "payments.post_payments.insurancePayment.payment_id"
                            )}
                            :
                          </span>
                        </td>
                        <td>
                          <span className="paymentFormValueText">
                            {insurancePaymentData.custom_payment_id}
                          </span>
                        </td>
                        <td className="paymentFormText">
                          {i18n.t(
                            "payments.post_payments.insurancePayment.payment_from"
                          )}
                          :
                        </td>
                        <td>
                          <span className="paymentFormValueText">
                            {insurancePaymentData.payer_name}
                          </span>
                        </td>
                        <td>
                          <span className="paymentFormText">
                            {i18n.t(
                              "payments.post_payments.insurancePayment.payment_mode"
                            )}
                            :
                          </span>
                        </td>
                        <td >
                          <span className="paymentFormValueText">
                            {insurancePaymentData.payment_mode_name}
                          </span>
                        </td>
                        <td style={{ textAlign: 'end' }}>
                          <span className="paymentFormText">
                            {i18n.t(
                              "payments.post_payments.insurancePayment.payment_reference"
                            )}
                            :
                          </span>
                        </td>
                        <td style={{ textAlign: 'end' }}>
                          <span className="paymentFormValueText">
                            {insurancePaymentData.reference_check_number}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="paymentFormText">
                            {i18n.t(
                              "payments.post_payments.insurancePayment.payment_date"
                            )}
                            :
                          </span>
                        </td>
                        <td>
                          <span className="paymentFormValueText">
                            {insurancePaymentData.payment_date
                              ?
                              CommonDateFormat(insurancePaymentData.payment_date)
                              : ""}
                          </span>
                        </td>
                        <td>
                          <span className="paymentFormText">
                            {i18n.t(
                              "payments.post_payments.insurancePayment.payment_amount"
                            )}
                            :
                          </span>
                        </td>
                        <td>
                          <span className="paymentFormValueText">
                            {currencyFormat(insurancePaymentData.amount)}
                          </span>
                        </td>
                        <td>
                          <span className="paymentFormText">
                            {i18n.t(
                              "payments.post_payments.insurancePayment.applied"
                            )}
                            :
                          </span>
                        </td>
                        <td>
                          <span className="paymentFormValueText">
                            {currencyFormat(Number(appliedAmount) ? Number(appliedAmount) : 0.00)}
                          </span>
                        </td>
                        <td style={{ textAlign: 'end' }}>
                          <span className="paymentFormText">
                            {i18n.t(
                              "payments.post_payments.insurancePayment.unapplied"
                            )}{" "}
                            :
                          </span>
                        </td>
                        <td style={{ textAlign: 'end' }}>
                          <span className="paymentFormValueText">
                            {currencyFormat(Number(unAppliedAmount) ? Number(unAppliedAmount) : 0.00)}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="paymentFormText">
                            {i18n.t(
                              "payments.post_payments.insurancePayment.payment_level_adjustment"
                            )}
                            :
                          </span>
                        </td>
                        <td>
                          <span className="paymentFormValueText">
                            {currencyFormat(insurancePaymentData.level_adjustments)}
                          </span>
                        </td>
                        <td>
                          <span className="paymentFormText">
                            {i18n.t("payments.post_payments.insurancePayment.adjustment_type")}:
                          </span>
                        </td>
                        <td>
                          <span className="paymentFormValueText">
                            {insurancePaymentData.adjustment_type?.length === 1 ? (
                              adjustmentTypes.find(item => item.id === insurancePaymentData.adjustment_type[0])?.name
                            ) : insurancePaymentData.adjustment_type?.length > 1 ? (
                              <>
                                <OverlayTrigger
                                  placement={"right"}
                                  delay={{ show: 250 }}
                                  overlay={
                                    <Popover id="popover-trigger-hover-focus">
                                      <div style={{ padding: '10px', maxWidth: '300px', minWidth: '200px' }}>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold' }} gutterBottom>
                                          Adjustment Types
                                        </Typography>
                                        <Divider sx={{ margin: '8px 0' }} />
                                        <div style={{ padding: '5px', textAlign: 'left' }}>
                                          {insurancePaymentData.adjustment_type.map((item, index) => (
                                            <div key={index}>
                                              <strong>{adjustmentTypes.find(adjustment => adjustment.id === item)?.name}</strong>
                                              <Divider sx={{ margin: '4px 0' }} />
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </Popover>
                                  }
                                >
                                  <div id='adjustment-types' style={{ display: 'inline-block' }}>
                                    {adjustmentTypes.find(item => item.id === insurancePaymentData.adjustment_type[0])?.name.length > 40
                                      ? `${adjustmentTypes.find(item => item.id === insurancePaymentData.adjustment_type[0])?.name.substring(0, 37)}...`
                                      : adjustmentTypes.find(item => item.id === insurancePaymentData.adjustment_type[0])?.name}{' '}
                                    <a  className="pl-2 link-text-color">+{insurancePaymentData.adjustment_type.length - 1} more...</a>
                                  </div>
                                </OverlayTrigger>
                              </>
                            ) : ""}
                          </span>
                        </td>
                        {editPaymentVariables.payment_mode === PAYEMNT_MODES_CREDITCARD_ID && (
                          <>
                            <td>
                              <span className="paymentFormText">
                                {i18n.t("payments.post_payments.credit_card")} :
                              </span>
                            </td>
                            <td>
                              <span className="paymentFormValueText">
                                {CREDIT_CARD_TYPES.map((item) => item.id === insurancePaymentData.card_type ? item.name : <></>)}
                              </span>
                            </td>
                          </>
                        )}
                      </tr>
                      <tr>
                        <td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                        <td style={{ textAlign: 'end' }}>
                          {checkPermission(permission_key_values_payments.payments_view_payments_modify,
                            permission_key_values_payments.post_payments
                          ) &&
                            <CommonButton
                              disabled={isEditButtondisable}
                              variant="contained"
                              onClick={showEditModalWindow}
                              label={i18n.t("commons.edit")}
                            />}
                        </td>
                      </tr>
                    </table>
                    <Divider style={{ marginLeft: -20, marginRight: -20, marginTop: 20 }} />
                    <div className="margin-top20">
                      <div className="row">
                        <div className="col pl-3">
                          <div className="font-bold div-inline">
                            {i18n.t(
                              "payments.post_payments.insurancePayment.selectClaim"
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row  padding-top10">
                        <div className="col-3 form-group  padding-top15">
                          <AsyncTypeInput
                            id="searchByClaimId"
                            labelKey="name"
                            minLength={0}
                            // onChange={setPatientList}
                            options={masterClaims}
                            onSearch={onSearchInsurances}
                            name="selectClaim"
                            value={claimSearchVariables.claim_id}
                            onValueChange={onHandleClaimIDChange}
                            selected={selectedClaimVals}
                            label={i18n.t(
                              "payments.post_payments.insurancePayment.searchByClaimId"
                            )}
                          />
                        </div>
                        <div className="col-3 padding-top15">
                          <AsyncTypeInput
                            id={"insurancePaymentPatient"}
                            name="searchPatient"
                            labelKey={"name"}
                            minLength={2}
                            options={patientDropdown}
                            onSearch={listPatientDropdown}
                            onValueChange={onHandleSearchClaim}

                            label={i18n.t(
                              "payments.post_payments.insurancePayment.searchPatient"
                            )}
                          />
                        </div>
                        <div className="col-3 padding-top40">
                          <CommonButton
                            icon="advanced"
                            variant="outlined"
                            onClick={showAdvancedOption}
                            label={i18n.t("commons.advancedSearch")}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="hidingView" id="hidingAdvancedOption">
                      <div className="row  margin-top10">
                        <div className="col-3">
                          <SelectInput
                            data={claimStatusList}
                            name="claim_status"
                            value={claimSearchVariables.claim_status}
                            onValueChange={onHandleSearchClaim}
                            label={i18n.t(
                              "payments.post_payments.insurancePayment.claimStatus"
                            )}
                          />
                        </div>
                        <div className="col-3">
                          <SelectInput
                            name="responsibility_type"
                            data={RESPONSIBILITY_TYPES}
                            value={claimSearchVariables.responsibility_type}
                            onValueChange={onHandleSearchClaim}
                            label={i18n.t(
                              "payments.post_payments.insurancePayment.responsibilityType"
                            )}
                          />
                        </div>
                        <div className="col-3">
                          <SelectInput
                            name="claim_type"
                            data={CLAIM_TYPES}
                            value={claimSearchVariables.claim_type}
                            onValueChange={onHandleSearchClaim}
                            label={i18n.t(
                              "payments.post_payments.insurancePayment.claimType"
                            )}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          {i18n.t(
                            "payments.post_payments.insurancePayment.claimDateRange"
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3">
                          <SelectInput
                            data={CLAIMDATEOFSERVICERANGE}
                            name="claim_date_type"
                            value={claimSearchVariables.claim_date_type}
                            onValueChange={onHandleSearchClaim}
                          />
                        </div>
                        <div className="col-3" style={{ marginTop: -20 }}>
                          <CalendarInput
                            name="claim_date_of_service_from"
                            selected={
                              claimSearchVariables.claim_date_of_service_from
                            }
                            maxDate={claimSearchVariables.claim_date_of_service_to ? claimSearchVariables.claim_date_of_service_to : new Date()}
                            onValueChange={(e) =>
                              onHandleSearchClaimDate(
                                e,
                                "claim_date_of_service_from"
                              )
                            }
                          />
                        </div>
                        <div className="col-3" style={{ marginTop: -20 }}>
                          <CalendarInput
                            name="claim_date_of_service_to"
                            selected={
                              claimSearchVariables.claim_date_of_service_to
                            }
                            minDate={claimSearchVariables.claim_date_of_service_from ? claimSearchVariables.claim_date_of_service_from : new Date()}
                            onValueChange={(e) =>
                              onHandleSearchClaimDate(
                                e,
                                "claim_date_of_service_to"
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          {i18n.t(
                            "payments.post_payments.insurancePayment.claimSubmitRange"
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3">
                          <SelectInput
                            data={CLAIMDATEOFSERVICERANGE}
                            name="claim_submitted_date_type"
                            value={claimSearchVariables.claim_submitted_date_type}
                            onValueChange={onHandleSearchClaim}
                          />
                        </div>
                        <div className="col-3" style={{ marginTop: -20 }}>
                          <CalendarInput
                            name="claim_submitted_date_from"
                            selected={
                              claimSearchVariables.claim_submitted_date_from
                            }
                            maxDate={claimSearchVariables.claim_submitted_date_to ? claimSearchVariables.claim_submitted_date_to :  new Date()}
                            onValueChange={(e) =>
                              onHandleSearchClaimDate(
                                e,
                                "claim_submitted_date_from"
                              )
                            }
                          />
                        </div>
                        <div className="col-3" style={{ marginTop: -20 }}>
                          <CalendarInput
                            name="claim_submitted_date_to"
                            selected={
                              claimSearchVariables.claim_submitted_date_to
                            }
                            minDate={claimSearchVariables.claim_submitted_date_from ? claimSearchVariables.claim_submitted_date_from : new Date()}
                            onValueChange={(e) =>
                              onHandleSearchClaimDate(
                                e,
                                "claim_submitted_date_to"
                              )
                            }
                          />
                        </div>
                        {/* <div className='col-2'>
                                                <button type="button" onClick={openSearchModal} className='btn btn-primary-blue' >{i18n.t('payments.post_payments.insurancePayment.search')}</button>
                                            </div> */}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-3">
                        <SelectInput
                          data={DROP_CHARGES_DATA}
                          selectOptionRemove={true}
                          defaultValues={"charges"}
                          onValueChange={onHandleChange}
                          name="selectCharge"
                          value={selectCharge}
                          label={i18n.t("payments.post_payments.charges")}
                        />
                      </div>

                      <div className="col-3">&nbsp;</div>

                      <div className="col-3 justify-right" style={{ marginTop: 25 }}>
                        {showResetButton ?
                          <CommonButton
                            variant='outlined'
                            label='Reset'
                            onClick={() => onReset()}
                            style={{ width: 'auto' }}
                          /> : null}
                        <button
                          type="button"
                          onClick={openSearchModal}
                          className="btn btn-primary-blue btn-common-height35"
                          id="hidingAdvancedOptionSearch"
                          style={{ display: "none" }}
                        >
                          {i18n.t(
                            "payments.post_payments.insurancePayment.search"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: 15 }}>
                    <table className='table' id='overflow_auto'>
                      {
                        (!procedureData || procedureData.length === 0) && (
                          <tr>
                            <td align="center" colSpan={20}>
                              {i18n.t("commons.noRecords")}{" "}
                            </td>
                          </tr>
                        )
                      }
                      <tbody>
                        {procedureData?.map((item, index) => {
                          if (
                            selectCharge === "charges" &&
                            parseFloat(item.balance) <= 0
                          )
                            return;
                          return (
                            <tr key={item.id ?? index}
                              style={index % 2 == 0 ?
                                { backgroundColor: '#fff', border: '1px solid #E0E0E0' } :
                                { backgroundColor: '#F5F5F5', border: '1px solid #E0E0E0' }}
                            >
                              <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                              >
                                <Grid item xs={12} style={{
                                  marginTop: 5
                                }}>
                                  <Stack
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={1}
                                  >
                                    <Stack
                                      key={item.id ?? index}
                                      direction="column"
                                      justifyContent="space-between"
                                      alignItems="flex-start"
                                      spacing={1}
                                      sx={{
                                        paddingTop: '10px',
                                        paddingLeft: '20px',
                                        paddingRight: '10px',
                                        paddingBottom: '10px',
                                        width: '12%'
                                      }}
                                    >
                                      <span>Claim ID</span>
                                      <Link
                                        to="#"
                                        className="advancedSearch"
                                        onClick={(e) => claimRedirection(e, item)}
                                      >
                                        {item.custom_claim_id}
                                      </Link>
                                    </Stack>
                                    <Stack
                                      key={item.id ?? index}
                                      direction="column"
                                      justifyContent="space-between"
                                      alignItems="flex-start"
                                      spacing={1}
                                      sx={{
                                        padding: '10px',
                                        width: '12%'
                                      }}
                                    >
                                      <span>DOS</span>
                                      <span>
                                        {item.dos_from
                                          ? CommonDateFormat(item.dos_from)

                                          : ""}
                                      </span>
                                    </Stack>
                                    <Stack
                                      key={item.id ?? index}
                                      direction="column"
                                      justifyContent="space-between"
                                      alignItems="flex-start"
                                      spacing={1}
                                      sx={{
                                        padding: '10px',
                                        width: '12%'
                                      }}
                                    >
                                      <span>Procedure</span>
                                      <span>
                                        {item.procedure}
                                      </span>
                                    </Stack>
                                    <Stack
                                      key={item.id ?? index}
                                      direction="column"
                                      justifyContent="space-between"
                                      alignItems="flex-start"
                                      spacing={1}
                                      sx={{
                                        padding: '10px',
                                        width: '12%'
                                      }}
                                    >
                                      <span>Charges</span>
                                      <span>
                                        {currencyFormat(item.charges)}
                                      </span>
                                    </Stack>
                                    <Stack
                                      key={item.id ?? index}
                                      direction="column"
                                      justifyContent="space-between"
                                      alignItems="flex-start"
                                      spacing={1}
                                      sx={{
                                        padding: '10px',
                                        width: '15%'
                                      }}
                                    >
                                      <span>Reciepts</span>
                                      <span>
                                        {currencyFormat(item.receipts)}
                                      </span>
                                    </Stack>
                                    <Stack
                                      key={item.id ?? index}
                                      direction="column"
                                      justifyContent="space-between"
                                      alignItems="flex-start"
                                      spacing={1}
                                      sx={{
                                        padding: '10px',
                                        width: '15%'
                                      }}
                                    >
                                      <span>Opening Balance</span>
                                      <span>
                                        {currencyFormat(item.opening_balance)}
                                      </span>
                                    </Stack>
                                    <Stack
                                      key={item.id ?? index}
                                      direction="column"
                                      justifyContent="space-between"
                                      alignItems="flex-start"
                                      spacing={1}
                                      sx={{
                                        padding: '10px',
                                        width: '20%'
                                      }}
                                    >
                                      <InputLabel style={labelStyle}>Allowed Amount</InputLabel>
                                      <div style={{ border: `0.5px solid ${procedureRowErrors[index]?.allowedAmount ? '#ee0d0d' : '#ced4da'}`, borderRadius: '3px', height: '2.6em', display: 'flex', justifyContent: 'center' }}>
                                        <Input
                                          type='text'
                                          className='table-input-era-posting'
                                          name='allowed_amount'
                                          value={
                                            procedureData &&
                                              Object.prototype.hasOwnProperty.call(procedureData, index) &&
                                              procedureData[index].allowed_amount !== undefined
                                              ? procedureData[index].allowed_amount
                                              : "0.00"
                                          }
                                          onChange={(e) => onHandleApply(e, index, 'allowed_amount')}
                                          startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                          endAdornment={
                                            procedureRowErrors[index]?.allowedAmount ?
                                              <InputAdornment position="end">
                                                <MUITooltip title={procedureRowErrors[index]?.allowedAmount} placement="top">
                                                  <ReportRoundedIcon style={{ color: '#ee0d0d' }} fontSize="small" />
                                                </MUITooltip>
                                              </InputAdornment>
                                              : null
                                          }
                                        />
                                      </div>
                                    </Stack>
                                    <Stack
                                      key={item.id ?? index}
                                      direction="column"
                                      justifyContent="space-between"
                                      alignItems="flex-start"
                                      spacing={1}
                                      sx={{
                                        padding: '10px',
                                        width: '20%'
                                      }}
                                    >
                                      <InputLabel style={labelStyle}>Paid Amount</InputLabel>
                                      <div style={{ border: `0.5px solid ${procedureRowErrors[index]?.paidAmount ? '#ee0d0d' : '#ced4da'}`, borderRadius: '3px', height: '2.6em', display: 'flex', justifyContent: 'center' }}>
                                        <Input
                                          type='text'
                                          name='paid_amount'
                                          className='table-input-era-posting'
                                          value={
                                            procedureData &&
                                              Object.prototype.hasOwnProperty.call(procedureData, index) &&
                                              procedureData[index].paid_amount !== undefined
                                              ? procedureData[index].paid_amount
                                              : "0.00"
                                          }
                                          onChange={(e) => onHandleApply(e, index, 'paid_amount')}
                                          onBlur={(e) => onBlurApply(e, index)}
                                          startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                          endAdornment={
                                            procedureRowErrors[index]?.paidAmount ?
                                              <InputAdornment position="end">
                                                <MUITooltip title={procedureRowErrors[index]?.paidAmount} placement="top">
                                                  <ReportRoundedIcon style={{ color: '#ee0d0d' }} fontSize="small" />
                                                </MUITooltip>
                                              </InputAdornment>
                                              : null
                                          }
                                        />
                                      </div>
                                    </Stack>
                                  </Stack>

                                </Grid>
                              </Grid>
                              <Divider orientation="horizontal" variant="middle" />
                              <Grid item xs={12}>
                                <Stack
                                  key={item.id ?? index}
                                  direction="row"
                                  justifyContent="flex-start"
                                  alignItems="flex-start"
                                  spacing={1}
                                >
                                  <Stack
                                    direction="column"
                                    justifyContent="space-between"
                                    alignItems="flex-start"
                                    spacing={1}
                                    sx={{
                                      paddingTop: '10px',
                                      paddingLeft: '20px',
                                      paddingRight: '10px',
                                      paddingBottom: '10px',
                                      width: '20%'
                                    }}
                                  >
                                    <InputLabel style={labelStyle}>Adjustment-1 Codes</InputLabel>
                                    <Stack direction="column" spacing={.5}>
                                      {item?.adjustment_code1_data?.map((item1, index2) => {
                                        return (
                                          <Stack key={index2} sx={{ border: '0.5px solid #ced4da', borderRadius: '3px', padding: '2px', width: '180px' }}>
                                            <div style={{ borderRadius: 3, display: 'flex', height: '18px' }}>
                                              <span style={{ marginLeft: 2 }}>{item1.label ?? item1.name.substring(0, 7)}</span>
                                              <IconButton aria-label="delete" onClick={() => handleDeleteAdjustment(item1, index2, index, "adjustment_code1_data")} style={{ marginLeft: 'auto', marginRight: 2 }}>
                                                <HighlightOffIcon />
                                              </IconButton>
                                            </div>
                                          </Stack>
                                        )
                                      })}

                                    </Stack>
                                    <Stack key={item.id ?? index} sx={{ width: '100%', marginTop: item?.adjustment_code1_data?.length ? '8px' : '0 !important' }}>
                                      <div style={{ width: '100%' }}>
                                        <AsyncTypeahead
                                          ref={(el) => typeHeadAdjustment1Ref.current[index] = el}
                                          id="search_adjustment_1_codes"
                                          labelKey="name"
                                          minLength={1}
                                          maxResults={maxResults}
                                          onPaginate={() =>
                                            setMaxResults(maxResults + 5)
                                          }
                                          paginationText={"Load More"}
                                          options={adjustment_1_codes_list}
                                          onSearch={handleSearchAdjustmentReason_1_code}
                                          name="selectClaim"
                                          onInputChange={(e) =>
                                            handleOPtions(
                                              e,
                                              "adjustment_1_codes_list"
                                            )
                                          }
                                          onKeyDown={(e) => selectOnTab(e)}
                                          onChange={(e) =>
                                            onHandleAdjustment_1_code(e, index)
                                          }
                                          override={{
                                            dropdownMenu: {
                                              maxHeight: "100px !important",
                                              overflowY: "scroll",
                                            },
                                          }}
                                          renderMenuItemChildren={(option) => {
                                            let description;
                                            let isDescriptionTooLong;
                                            description = option.name;
                                            isDescriptionTooLong =
                                              description.length > 30;
                                            return (
                                              <div>
                                                <OverlayTrigger
                                                  placement={"right"}
                                                  delay={{ show: 250 }}
                                                  overlay={
                                                    <Tooltip id={`tooltip-right`}>
                                                      {option.name}
                                                    </Tooltip>
                                                  }
                                                >
                                                  <div
                                                    onClick={(e) =>
                                                      onHandleAdjustment_1_code(
                                                        e,
                                                        index
                                                      )
                                                    }
                                                  >
                                                    <strong>{`${option.label}    `}</strong>
                                                    <span>
                                                      {isDescriptionTooLong
                                                        ? `- ${description.substring(
                                                          0,
                                                          30
                                                        )}...`
                                                        : `- ${description}`}
                                                    </span>
                                                  </div>
                                                </OverlayTrigger>
                                              </div>
                                            );
                                          }}
                                        />
                                      </div>
                                    </Stack>
                                  </Stack>
                                  <Stack
                                    key={item.id ?? index}
                                    direction="column"
                                    justifyContent="space-between"
                                    alignItems="flex-start"
                                    spacing={1}
                                    sx={{
                                      padding: '10px',
                                      width: '20%'
                                    }}
                                  >
                                    <InputLabel style={labelStyle}>Adjustment-1 Amt</InputLabel>
                                    <Stack sx={{ width: '100%' }}>
                                      <Stack direction="column" spacing={.5} alignItems="flex-start" >
                                        {item?.adjustment_code1_data?.map((adjstmntCode1) => {
                                          return (
                                            <Stack key={adjstmntCode1.id} sx={{ border: '0.5px solid #ced4da', borderRadius: '3px', padding: '2px' }}>
                                              <OverlayTrigger placement={'right'} delay={{ show: 250 }} overlay={
                                                <Tooltip id={`tooltip-index`} style={{ opacity: 0.4, zIndex: 10000 }}>
                                                  {adjstmntCode1.label}
                                                </Tooltip>
                                              } >
                                                <Input
                                                  sx={{
                                                    padding: '3px',
                                                    fontSize: '1rem',
                                                    height: '18px',
                                                    width: '100%',
                                                  }}
                                                  name='adjustment_amount1'
                                                  id="standard-adornment-amount"
                                                  type="text"
                                                  inputMode="numeric"
                                                  pattern="[0-9]*"
                                                  step="any"
                                                  value={adjstmntCode1.amount}
                                                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                  onChange={(e) => { handleAdjustmentAmountChange(e, adjstmntCode1, index, 'adjustment_code1_data') }}
                                                  onBlur={(e) => onBlurApply(e, index, adjstmntCode1.id)}
                                                  endAdornment={
                                                    procedureRowErrors[index]?.adjustment_amount1?.[adjstmntCode1.id]?.id === adjstmntCode1.id ?
                                                      <InputAdornment position="end">
                                                        <MUITooltip title={procedureRowErrors[index]?.adjustment_amount1?.[adjstmntCode1.id]?.message} placement="top">
                                                          <ReportRoundedIcon style={{ color: '#ee0d0d' }} fontSize="small" />
                                                        </MUITooltip>
                                                      </InputAdornment>
                                                      : null
                                                  }
                                                />
                                              </OverlayTrigger>
                                            </Stack>
                                          )
                                        })}
                                      </Stack>
                                    </Stack>
                                  </Stack>
                                  <Stack
                                    key={item.id ?? index}
                                    direction="column"
                                    justifyContent="space-between"
                                    alignItems="flex-start"
                                    spacing={1}
                                    sx={{
                                      padding: '10px',
                                      width: '20%'
                                    }}
                                  >
                                    <InputLabel style={labelStyle}>Adjustment-2 Codes</InputLabel>
                                    <Stack direction="column" spacing={.5}>
                                      {item?.adjustment_code2_data?.map((item1, index2) => {
                                        return (
                                          <Stack key={index2} sx={{ border: '0.5px solid #ced4da', borderRadius: '3px', padding: '2px', width: '180px' }}>
                                            <div style={{ borderRadius: 3, display: 'flex', height: '18px' }}>
                                              <span style={{ marginLeft: 2 }}>{item1.label ?? item1.name.substring(0, 7)}</span>
                                              <IconButton aria-label="delete" onClick={() => handleDeleteAdjustment(item1, index2, index, "adjustment_code2_data")} style={{ marginLeft: 'auto', marginRight: 2 }}>
                                                <HighlightOffIcon />
                                              </IconButton>
                                            </div>
                                          </Stack>
                                        )
                                      })}
                                    </Stack>
                                    <Stack key={item.id ?? index} sx={{ width: '100%', marginTop: item?.adjustment_code2_data?.length ? '8px' : '0 !important' }}>
                                      <div style={{ width: '100%' }}>
                                        <AsyncTypeahead
                                          ref={(el) => typeHeadAdjustment2Ref.current[index] = el}
                                          id="search_adjustment_2_codes"
                                          labelKey="name"
                                          minLength={1}
                                          maxHeight={"150px"}
                                          maxResults={maxResults}
                                          onPaginate={() =>
                                            setMaxResults(maxResults + 5)
                                          }
                                          paginationText={"Load More"}
                                          options={adjustment_2_codes_list}
                                          onSearch={handleSearchAdjustmentReason_2_code}
                                          name="selectClaim"
                                          onKeyDown={(e) => selectOnTab(e)}
                                          onInputChange={(e) =>
                                            handleOPtions(
                                              e,
                                              "adjustment_2_codes_list"
                                            )
                                          }
                                          onChange={(e) =>
                                            onHandleAdjustment_2_code(e, index)
                                          }
                                          override={{
                                            dropdownMenu: {
                                              maxHeight: "100px !important",
                                              overflowY: "scroll",
                                            },
                                          }}
                                          renderMenuItemChildren={(option) => {
                                            let description;
                                            let isDescriptionTooLong;
                                            description = option.name;
                                            isDescriptionTooLong =
                                              description.length > 30;
                                            return (
                                              <div>
                                                <OverlayTrigger
                                                  placement={"right"}
                                                  delay={{ show: 250 }}
                                                  overlay={
                                                    <Tooltip id={`tooltip-right`}>
                                                      {option.name}
                                                    </Tooltip>
                                                  }
                                                >
                                                  <div
                                                    onClick={(e) =>
                                                      onHandleAdjustment_2_code(
                                                        e,
                                                        index
                                                      )
                                                    }
                                                  >
                                                    <strong>{`${option.label}  `}</strong>
                                                    <span>
                                                      {isDescriptionTooLong
                                                        ? `- ${description.substring(
                                                          0,
                                                          30
                                                        )}...`
                                                        : `- ${description}`}
                                                    </span>
                                                  </div>
                                                </OverlayTrigger>
                                              </div>
                                            );
                                          }}
                                        />
                                      </div>
                                    </Stack>

                                  </Stack>
                                  <Stack
                                    key={item.id ?? index}
                                    direction="column"
                                    justifyContent="space-between"
                                    alignItems="flex-start"
                                    spacing={1}
                                    sx={{
                                      padding: '10px',
                                      width: '20%'
                                    }}
                                  >
                                    <InputLabel style={labelStyle}>Adjustment-2 Amt</InputLabel>
                                    <Stack sx={{ width: '100%' }}>
                                      <Stack direction="column" spacing={.5} alignItems="flex-start" >
                                        {item?.adjustment_code2_data?.map((adjstmntCode2) => {
                                          return (
                                            <Stack key={adjstmntCode2.id} sx={{ border: `0.5px solid ${procedureRowErrors[index]?.adjustment_amount2?.[adjstmntCode2.id] ? '#ee0d0d' : '#ced4da'}`, borderRadius: '3px', padding: '2px' }}>
                                              <OverlayTrigger placement={'right'} delay={{ show: 250 }} overlay={
                                                <Tooltip id={`tooltip-index`} style={{ opacity: 0.4, zIndex: 10000 }}>
                                                  {adjstmntCode2.label}
                                                </Tooltip>
                                              } >
                                                <Input
                                                  sx={{
                                                    padding: '3px',
                                                    fontSize: '1rem',
                                                    height: '18px',
                                                    width: '100%',
                                                  }}
                                                  id="standard-adornment-amount"
                                                  type="text"
                                                  inputMode="numeric"
                                                  name='adjustment_amount2'
                                                  pattern="[0-9]*"
                                                  step="any"
                                                  value={adjstmntCode2.amount}
                                                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                  onChange={(e) => { handleAdjustmentAmountChange(e, adjstmntCode2, index, 'adjustment_code2_data') }}
                                                  onBlur={(e) => onBlurApply(e, index, adjstmntCode2.id)}
                                                  endAdornment={
                                                    procedureRowErrors[index]?.adjustment_amount2?.[adjstmntCode2.id]?.id === adjstmntCode2.id ?
                                                      <InputAdornment position="end">
                                                        <MUITooltip title={procedureRowErrors[index]?.adjustment_amount2?.[adjstmntCode2.id]?.message} placement="top">
                                                          <ReportRoundedIcon style={{ color: '#ee0d0d' }} fontSize="small" />
                                                        </MUITooltip>
                                                      </InputAdornment>
                                                      : null
                                                  }
                                                />
                                              </OverlayTrigger>
                                            </Stack>
                                          )
                                        })}
                                      </Stack>
                                    </Stack>
                                  </Stack>
                                  <Stack
                                    key={item.id ?? index}
                                    direction="column"
                                    justifyContent="space-between"
                                    alignItems="flex-start"
                                    spacing={1}
                                    sx={{
                                      padding: '10px',
                                      width: '20%'
                                    }}
                                  >
                                    <InputLabel style={labelStyle}>Copay</InputLabel>
                                    <div style={{ border: '0.5px solid #ced4da', borderRadius: '3px', height: '2.6em', display: 'flex', justifyContent: 'center' }}>
                                      <Input
                                        id="copay"
                                        type="text"
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                        step="any"
                                        sx={{ border: 'none !important' }}
                                        className='table-input-era-posting'
                                        name='copay'
                                        disabled={item.save_procedure_id && procedureData[index].pay_procedure_status === 0}
                                        onChange={(e) => {
                                          onHandleApply(e, index, "copay");
                                          updatePRTotal(
                                            Number(procedureData[index].deductibles) +
                                            Number(procedureData[index].co_insurance) +
                                            Number(procedureData[index].copay)
                                          );
                                        }}
                                        onBlur={(e) => onBlurApply(e, index)}
                                        value={
                                          procedureData &&
                                            Object.prototype.hasOwnProperty.call(procedureData, index) &&
                                            procedureData[index].copay !== undefined
                                            ? procedureData[index].copay
                                            : "0.00"
                                        }
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                      />
                                    </div>
                                  </Stack>
                                  <Stack
                                    key={item.id ?? index}
                                    direction="column"
                                    justifyContent="space-between"
                                    alignItems="flex-start"
                                    spacing={1}
                                    sx={{
                                      paddingRight: '20px',
                                      paddingTop: '10px',
                                      paddingLeft: '10px',
                                      paddingBottom: '10px',
                                      width: '20%' // 20% width for the last two stacks
                                    }}
                                  >
                                    <InputLabel style={labelStyle}>Co-Insurance</InputLabel>
                                    <div style={{ border: '0.5px solid #ced4da', borderRadius: '3px', height: '2.6em', display: 'flex', justifyContent: 'center' }}>
                                      <Input
                                        id="coInsurance"
                                        type="text"
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                        step="any"
                                        sx={{ border: 'none !important' }}
                                        className='table-input-era-posting'
                                        name="co_insurance"
                                        value={
                                          procedureData &&
                                            Object.prototype.hasOwnProperty.call(procedureData, index) &&
                                            procedureData[index].co_insurance !== undefined
                                            ? procedureData[index].co_insurance
                                            : "0.00"
                                        }
                                        disabled={item.save_procedure_id && procedureData[index].pay_procedure_status === 0}
                                        onChange={(e) => {
                                          onHandleApply(e, index, "copay");
                                          updatePRTotal(
                                            Number(procedureData[index].deductibles) +
                                            Number(procedureData[index].co_insurance) +
                                            Number(procedureData[index].copay)
                                          );
                                        }}
                                        onBlur={(e) => onBlurApply(e, index)}
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                      />
                                    </div>
                                  </Stack>
                                </Stack>
                              </Grid>
                              <Divider orientation="horizontal" variant="middle" />
                              <Grid item xs={12} style={{ marginBottom: 5 }}>
                                <Stack
                                  key={item.id ?? index}
                                  direction="row"
                                  justifyContent="flex-start"
                                  alignItems="flex-start"
                                  spacing={1}
                                >
                                  <Stack
                                    direction="column"
                                    justifyContent="space-between"
                                    alignItems="flex-start"
                                    spacing={1}
                                    sx={{
                                      paddingTop: '10px',
                                      paddingLeft: '20px',
                                      paddingRight: '10px',
                                      paddingBottom: '10px',
                                      width: '20%'
                                    }}
                                  >
                                    {/* DEDUCTIBLE */}
                                    <InputLabel style={labelStyle}>Deductible</InputLabel>
                                    <div style={{ border: '0.5px solid #ced4da', borderRadius: '3px', height: '2.6em', display: 'flex', justifyContent: 'center' }}>
                                      <Input
                                        id="deductible"
                                        type="text"
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                        step="any"
                                        sx={{ border: 'none !important' }}
                                        className='table-input-era-posting'
                                        name="deductibles"
                                        disabled={item.save_procedure_id && procedureData[index].pay_procedure_status === 0}
                                        onChange={(e) => {
                                          onHandleApply(e, index, "co_insurance");
                                          updatePRTotal(
                                            Number(procedureData[index].deductibles) +
                                            Number(procedureData[index].co_insurance) +
                                            Number(procedureData[index].copay)
                                          );
                                        }}
                                        onBlur={(e) => onBlurApply(e, index)}
                                        value={
                                          procedureData &&
                                            Object.prototype.hasOwnProperty.call(procedureData, index) &&
                                            procedureData[index].deductibles !== undefined
                                            ? procedureData[index].deductibles
                                            : "0.00"
                                        }
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                      />
                                    </div>
                                  </Stack>
                                  <Stack
                                    key={item.id ?? index}
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={1}
                                    sx={{
                                      padding: '10px',
                                      width: '12%' // Conditional width based on index
                                    }}
                                  >
                                    <InputLabel style={labelStyle}>PR Total</InputLabel>
                                    <Box style={{ flexGrow: 1 }} />
                                    <Typography style={typographyStyle}> {currencyFormat(
                                      prTotal
                                        ? prTotal
                                        : Number(procedureData[index].deductibles ?? 0) +
                                        Number(procedureData[index].co_insurance ?? 0) +
                                        Number(procedureData[index].copay ?? 0)
                                    )}</Typography>
                                  </Stack>
                                  <Stack
                                    key={item.id ?? index}
                                    direction="column"
                                    justifyContent="space-between"
                                    alignItems="flex-start"
                                    spacing={1}
                                    sx={{
                                      padding: '10px',
                                      width: '18%' // 20% width for the last two stacks
                                    }}
                                  >
                                    {/* REMARK-CODES */}
                                    <InputLabel style={labelStyle}>Remark Codes</InputLabel>
                                    <div style={{ marginTop: '-1.5px' }}>
                                      <RemitanceCodeMultiSelect
                                        name={"labelRemarkCodes"}
                                        width={150}
                                        onValueChange={(e) =>
                                          onHandleMultiSelectSearchForRemarkCodes(
                                            e,
                                            index
                                          )
                                        }
                                        selectedValues={procedureData[index]?.selectedRemarkCodes || []}
                                      />
                                    </div>
                                  </Stack>
                                  <Stack
                                    key={item.id ?? index}
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={1}
                                    sx={{
                                      padding: '10px',
                                      width: '15%' // Conditional width based on index
                                    }}
                                  >
                                    <InputLabel style={labelStyle}>Closing Balance</InputLabel>
                                    <Box style={{ flexGrow: 1 }} />
                                    <Typography style={typographyStyle}>{Object.prototype.hasOwnProperty.call(procedureData, index)
                                      ? currencyFormat(
                                        procedureData[index].closing_balance
                                      )
                                      : currencyFormat(1)}</Typography>
                                  </Stack>
                                  <Stack
                                    direction="column"
                                    justifyContent="space-between"
                                    alignItems="flex-start"
                                    spacing={1}
                                    sx={{
                                      padding: '10px',
                                      width: '20%' // 20% width for the last two stacks
                                    }}
                                  >
                                    <InputLabel style={labelStyle}>Responsibility</InputLabel>
                                    <div className="form-group_none pl-0" style={{ marginTop: -5 }}>
                                      <OverlayTrigger
                                        placement="bottom"
                                        trigger={"click"}
                                        rootClose={true}
                                        delay={{ show: 250 }}
                                        overlay={
                                          <Popover id="popover-trigger-hover-focus" className="custom-popover">
                                            <div className="popover-content">
                                              {isFetchingResponsibilityData && (
                                                <div style={{ width: '140px' }}>
                                                  <Skeleton variant="rectangular" height={20} width="70%" animation="wave" sx={{ margin: '8px', borderRadius: '5px' }} />
                                                  <Divider />
                                                  <Skeleton variant="rectangular" height={20} width="70%" animation="wave" sx={{ margin: '8px', borderRadius: '5px' }} />
                                                  <Divider />
                                                  <Skeleton variant="rectangular" height={20} width="70%" animation="wave" sx={{ margin: '8px', borderRadius: '5px' }} />
                                                </div>
                                              )}
                                              {
                                                isFetchingResponsibilityData === false && responsibilityData?.length > 0 &&
                                                (
                                                  <List component="nav">
                                                    {responsibilityData.map((item, index1) => (
                                                      <React.Fragment key={index1}>
                                                        <ListItem
                                                          onClick={() => {
                                                            onHandleApply(
                                                              {
                                                                target: {
                                                                  value: item.id
                                                                }
                                                              },
                                                              index,
                                                              "responsibility_type"
                                                            )
                                                          }}
                                                          sx={{ cursor: 'pointer', '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.08)' } }}
                                                        >
                                                          <ListItemText primaryTypographyProps={{ sx: { fontSize: '10px' } }}>
                                                            {item.name}
                                                          </ListItemText>
                                                          {
                                                            item.id === procedureData[index].responsibility_type && (
                                                              <>
                                                                <ListItemIcon sx={{ marginLeft: '40px' }}>
                                                                  <Check fontSize='medium' />
                                                                </ListItemIcon>
                                                              </>
                                                            )
                                                          }
                                                        </ListItem>
                                                        <Divider sx={{ marginBottom: '-3px' }} />
                                                      </React.Fragment>
                                                    ))}
                                                  </List>
                                                )}
                                            </div>
                                          </Popover>
                                        }
                                      >
                                        <div className="custom-popover-trigger" onClick={() => handleResponsibilityTypeClick(procedureData[index])}>
                                          <div
                                            style={{ marginRight: '5px' }}
                                          >
                                            {RESPONSIBILITY_TYPES.find(item => item.id === procedureData[index].responsibility_type)?.name ?? ""}
                                          </div>
                                          <ArrowDropDownIcon style={{ marginLeft: 50 }} />
                                        </div>
                                      </OverlayTrigger>
                                    </div>
                                  </Stack>

                                  <Stack
                                    key={item.id ?? index}
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={1}
                                    sx={{
                                      padding: '10px',
                                      width: '15%' // Conditional width based on index
                                    }}
                                  >
                                    <InputLabel style={labelStyle}>Claim Status</InputLabel>
                                    <Box style={{ flexGrow: 1 }} />
                                        <div style={{maxWidth: "142px", marginTop: -5 }}>
                                        <SelectInput
                                          className={!procedureData[index].claim_status_id ? "input-error" : ''}
                                          data={claimStatusList?.filter(item => item.priorities?.includes(procedureData[index]?.responsibility_type))}
                                          name="claim_status_id"
                                          onValueChange={(e) => onHandleApply(e, index, 'claim_status_id')}
                                          value={procedureData[index].claim_status_id}
                                        />
                                        </div>
                                  </Stack>
                                  <Stack
                                    key={item.id ?? index}
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={1}
                                    sx={{
                                      padding: '10px',
                                      width: '15%' // Conditional width based on index
                                    }}
                                  >
                                    <InputLabel style={labelStyle}>Claim Sub Status</InputLabel>
                                    <Box style={{ flexGrow: 1 }} />
                                      <div style={{ maxWidth: "142px", marginTop: -5 }}>
                                        <SelectInput
                                          className={claimSubStatusList?.filter(item => item.parent_claim_status_ids?.includes(procedureData[index]?.claim_status_id))?.length && !procedureData[index].claim_sub_status_id  ? "input-error": ""}
                                          data={claimSubStatusList?.filter(item => item.parent_claim_status_ids?.includes(procedureData[index]?.claim_status_id) || item.id == procedureData[index].claim_sub_status_id)}
                                          name="claim_sub_status_id"
                                          value={procedureData[index].claim_sub_status_id}
                                          onValueChange={(e) => onHandleApply(e, index, 'claim_sub_status_id')}
                                        />
                                      </div>

                                  </Stack>


                                  <div style={{ marginRight: 20, marginTop: 25, display: 'flex', gap: '5px' }}>

                                    {item.save_procedure_id &&
                                      procedureData[index].pay_procedure_status ===
                                      0 &&
                                      props.updatePayment && (
                                        <OverlayTrigger
                                          placement="top"
                                          delay={{ show: 250 }}
                                          overlay={
                                            <Tooltip
                                              id={`tooltip-right`}
                                              style={{
                                                opacity: 0.4,
                                              }}
                                            >
                                              {i18n.t(
                                                "payments.post_payments.insurancePayment.table2.reverse"
                                              )}
                                            </Tooltip>
                                          }
                                        >
                                          <IconButton sx={{ background: '#fff', borderRadius: '8px' }}
                                            disabled={
                                              procedureData[index].entry_reversed
                                                ? true
                                                : false
                                            }
                                            onClick={(e) => reversePayment(e, index)}>
                                            <UndoIcon fontSize='23px' /></IconButton>
                                        </OverlayTrigger>
                                      )}

                                    {!item.save_procedure_id &&
                                      item.has_payment && (
                                        <OverlayTrigger
                                          placement="top"
                                          delay={{ show: 250 }}
                                          overlay={
                                            <Tooltip
                                              id={`tooltip-right`}
                                              style={{
                                                opacity: 0.4,
                                              }}
                                            >
                                              {i18n.t(
                                                "payments.post_payments.insurancePayment.table2.reverseAll"
                                              )}
                                            </Tooltip>
                                          }
                                        >
                                          <IconButton sx={{ background: '#fff', borderRadius: '8px' }}
                                            onClick={(e) => reverseAllInsurancePayments(
                                              e,
                                              index,
                                              item
                                            )}>
                                            <ReplayCircleFilledIcon fontSize='23px' /></IconButton>
                                        </OverlayTrigger>
                                      )}

                                    <OverlayTrigger
                                      placement="top"
                                      delay={{ show: 250 }}
                                      overlay={
                                        <Tooltip
                                          id={`tooltip-right`}
                                          style={{
                                            opacity: 0.4,
                                          }}
                                        >
                                          {i18n.t(
                                            "payments.post_payments.insurancePayment.table2.remove"
                                          )}
                                        </Tooltip>
                                      }
                                    >
                                      <IconButton sx={{ background: '#fff', borderRadius: '8px' }}
                                        disabled={
                                          procedureData[index].entry_reversed
                                            ? true
                                            : false
                                        }
                                        onClick={(e) => removeProcedure(e, index)}>
                                        <DeleteIcon fontSize='23px' /></IconButton>
                                    </OverlayTrigger>
                                  </div>

                                </Stack>
                              </Grid>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                    <div className="row margin-top15">
                      <div className="col">
                        <div className="justify-right">
                          <div className='margin-right15'>
                            <CommonButton
                              variant="outlined"
                              onClick={onCancelPaymentProcedures}
                              label={i18n.t("commons.cancel")}
                            />
                          </div>
                          {checkPermission(permission_key_values_payments.payments_view_payments_modify,
                            permission_key_values_payments.post_payments
                          ) &&
                            <div className='margin-right20'>
                              <CommonButton
                                variant="contained"
                                onClick={onSavePaymentProcedures}
                                label={i18n.t("commons.save")}
                              />
                            </div>}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              <div className="">
                <CustomizedDialogs
                  header={header}
                  showModal={showPostAsCreditModal}
                  setShowModalWindow={setShowPostAsCreditModal}
                  resetForm={resetForm}
                >
                  Payment Has Been Posted To Patient - John,Doe <br />
                  <br />
                  Posted Credit - {currencyFormat(100)}
                  <br />
                  Total Credits - {currencyFormat(150)}
                  <br />
                  Patient Due - {currencyFormat(150)}
                  <br />
                  Total Patient Balance - {currencyFormat(0)}
                </CustomizedDialogs>
              </div>
              <div className="">
                <CustomizedDialogs
                  modalId="longWidth"
                  header={header}
                  showModal={searchModalOpen}
                  setShowModalWindow={setSearchModalOpen}
                  resetForm={resetFormSearch}
                >
                  <div className="row">
                    <div className="col-3">
                      <SelectInput
                        selectOptionRemove={true}
                        defaultValues={defaultSearchOption}
                        data={CLAIM_SEARCH_OPTIONS}
                        name="searchOption"
                        value={searchOption}
                        onValueChange={onHandleChangeSearchOption}
                        label={i18n.t(
                          "payments.post_payments.insurancePayment.searchResults"
                        )}
                      />
                    </div>
                    <div className="col-3">
                      <TextInput
                        type="text"
                        name="searchText"
                        value={searchText}
                        onValueChange={onHandleChangeSearchOption}
                        label={i18n.t(
                          "payments.post_payments.insurancePayment.search"
                        )}
                      />
                    </div>
                    <div className="padding-top15">
                      <button
                        type="button"
                        onClick={onClickSearchClaim}
                        className="btn btn-primary-blue margin-left12 margin-top10"
                      >
                        {i18n.t(
                          "payments.post_payments.insurancePayment.search"
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="margin-top5">
                    <table className="table">
                      <thead>
                        <tr>
                          {claimListHeadings.map((item, index) => {
                            return <th key={index}>{item}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {(!claimList || claimList.length === 0) && (
                          <tr>
                            <td align="center" colSpan={13}>
                              {i18n.t("commons.noRecords")}{" "}
                            </td>
                          </tr>
                        )}
                        {claimList.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td align="center">
                                <Link
                                  to="#"
                                  className="advancedSearch"
                                  onClick={(e) => postClaim(e, item.id)}
                                >
                                  {i18n.t(
                                    "payments.post_payments.insurancePayment.table1.post"
                                  )}
                                </Link>
                              </td>
                              <td align="left">{item.custom_claim_id}</td>
                              <td align="left">{item.patient_name}</td>
                              <td align="left">{item.custom_patient_id}</td>
                              <td align="left">{item.billing_provider}</td>
                              <td align="center">
                                {item.service_from_date
                                  ? CommonDateFormat(item.service_from_date)
                                  : ""}
                              </td>
                              <td>{item.submitted}</td>
                              <td className="small" align="right">
                                {currencyFormat(item.charges)}
                              </td>
                              <td className="small" align="right">
                                {currencyFormat(item.payments)}
                              </td>
                              <td className="small" align="right">
                                {currencyFormat(item.adjustments)}
                              </td>
                              <td className="small" align="right">
                                {currencyFormat(item.balance)}
                              </td>
                              <td align="left">{item?.resp_payer?.name || 'Paid'}</td>
                              <td align="left">{item.claim_status}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </CustomizedDialogs>
              </div>
              <div className="">
                <CustomizedDialogs
                  header={header}
                  type="save"
                  showModal={showEditPaymentModal}
                  setShowModalWindow={setShowEditPaymentModal}
                  resetForm={resetEditPaymentForm}
                >
                  <Form
                    id="form_dataEntry"
                    autoComplete="off"
                    onSubmit={(e) => onSaveFormData(e)}
                    encType="multipart/form-data"
                  >
                    <div className="">
                      <div className="">
                        <div className="row">
                          <div className="col-md-6">
                            <TextInput
                              type="text"
                              disabled={true}
                              name="custom_payment_id"
                              value={editPaymentVariables.custom_payment_id}
                              onValueChange={onHandleChangeEditPayment}
                              label={i18n.t(
                                "payments.post_payments.editPaymentModal.payment_id"
                              )}
                            />
                          </div>
                          <div className="col-md-6">
                            <AsyncTypeInput
                              labelKey="name"
                              name="payer"
                              class_name={editPaymentVariables.payer == "" ? "input-error" : ""}
                              minLength={1}
                              placeholder={""}
                              onValueChange={handleSearchPayerEdit}
                              options={payersList}
                              onSearch={onSearchPayer}
                              id={"payer"}
                              label={i18n.t(
                                "payments.post_payments.insurancePayment.payer"
                              )}
                              clearButton={true}
                              selected={selectedPayer}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <CalendarInput
                              name="payment_date"
                              required={true}
                              selected={editPaymentVariables.payment_date}
                              onValueChange={(e) =>
                                onHandleChangeEditPaymentDate(e, "payment_date")
                              }
                              label={i18n.t(
                                "payments.post_payments.editPaymentModal.payment_date"
                              )}
                            />
                          </div>
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md">
                                <SelectInput
                                  data={INSURANCEPAYMENTMODES}
                                  name="payment_mode"
                                  required={true}
                                  value={editPaymentVariables.payment_mode}
                                  onValueChange={onHandleChangeEditPayment}
                                  label={i18n.t(
                                    "payments.post_payments.editPaymentModal.payment_mode"
                                  )}
                                />
                              </div>
                              {editPaymentVariables.payment_mode ===
                                PAYEMNT_MODES_CREDITCARD_ID && (
                                  <div className="col-md pl-0">
                                    <SelectInput
                                      name="card_type"
                                      data={CREDIT_CARD_TYPES}
                                      value={editPaymentVariables.card_type}
                                      onValueChange={onHandleChangeEditPayment}
                                      label={i18n.t(
                                        "payments.post_payments.credit_card"
                                      )}
                                      showCreditCardIcons={true}
                                      className={
                                        editPaymentVariables.card_type
                                          ? ""
                                          : "input-error"
                                      }
                                      required={true}
                                    />
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <TextInput
                              type="text"
                              name="reference_check_number"
                              required={true}
                              value={
                                editPaymentVariables.reference_check_number
                              }
                              onValueChange={onHandleChangeEditPayment}
                              label={i18n.t(
                                "payments.post_payments.editPaymentModal.payment_reference"
                              )}
                            />
                          </div>

                          <div className="col-md-6">
                            <TextInput
                              type="number"
                              name="amount"
                              required={true}
                              value={editPaymentVariables.amount}
                              onValueChange={onHandleChangeEditPayment}
                              label={i18n.t(
                                "payments.post_payments.editPaymentModal.payment_amount"
                              )}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <TextInput
                              type="number"
                              name="level_adjustments"
                              value={editPaymentVariables.level_adjustments}
                              onValueChange={onHandleChangeEditPayment}
                              label={i18n.t(
                                "payments.post_payments.insurancePayment.payment_level_adjustments"
                              )}
                            />
                          </div>
                          <div className="col-md-6">
                            <MaterialMultiselect
                              name="adjustment_type"
                              value={editPaymentVariables.adjustment_type}
                              required={editPaymentVariables.level_adjustments == 0 ? false : true}
                              onValueChange={onHandleChangeEditPayment}
                              options={adjustmentTypes}
                              label={i18n.t("payments.post_payments.insurancePayment.adjustment_type")}
                            />
                            {/* Display FormHelperText for error */}
                            {
                              editPaymentVariables.level_adjustments <= 0 && editPaymentVariables.adjustment_type?.length >= 1 && (
                                <FormHelperText error>
                                  {i18n.t(
                                    "payments.post_payments.insurancePayment.paymenMessages.paymentAdjstmentVsAdjustmentType"
                                  )}
                                </FormHelperText>
                              )
                            }
                          </div >
                        </div >
                        <div className="row">
                          <div className="col">
                            <TextAreaInput
                              name="note"
                              value={editPaymentVariables.note}
                              onValueChange={onHandleChangeEditPayment}
                              rows={3}
                              label={i18n.t(
                                "payments.post_payments.editPaymentModal.payment_note"
                              )}
                            />
                          </div>
                        </div>
                      </div >
                    </div >
                  </Form >
                </CustomizedDialogs >
              </div >
            </Form >
          </div >
        </div >
      </div >
      <CustomizedSmallDialogs
        showModal={showAlertModalWindow}
        header={i18n.t("commons.alert")}
        alertOK={onAlertOk}
        type="alert"
        setShowModalWindow={setShowAlertModalWindow}
      >
        {alertMessage}
      </CustomizedSmallDialogs>
    </React.Fragment >
  );
};

export default InsurancePayments